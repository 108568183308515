.Staff_Page{
    display: grid;
 //  background-color: brown;
    width: 80%;
    margin: auto;
    grid-template-columns:  auto;
    grid-template-rows:  .75fr  auto;
    grid-area: 
    "title"
    "doc_cards";
 //   margin-top: 100px;
 //   margin-bottom: 100px;
 padding-top: 100px;
 padding-bottom: 100px;
    position: relative;
    min-height: 100vh;
.title{ 
//    grid-area: title;
    width: 80%;
    margin: auto;
//background-color: blue;
 // margin-top: 100px;
  margin-bottom:25px;
    &:hover{
    transition: 1s;
      
        .outline{
    transition: 1s;

            -webkit-text-stroke: 1px rgb(255, 255, 255);
            color: #359c8d;
        }
    }
    h5{
       
       text-align: center;
       font-size: 65px;
        color: #000;    
       font-family: darkmode-on;
     
    }
    .outline{
        -webkit-text-stroke: 1px rgb(0, 0, 0);
        color: transparent;
       }
}
.directors {
    display: grid;

   
    width: 80%;
    margin: auto;
    &.doctorspage{

//background-color: #359c8d;
        
        .doc_cards{
       //     grid-area: doc_cards;
        //  background-color: rgb(38, 231, 167);
         //   margin-bottom: 150px;
        }
    }
}
}



@media (min-width: 600px) {
    .Staff_Page{
    grid-template-rows:  .05fr   auto;

        .doc_cards{

        }
    }
}
@media (min-width: 1366px) {
    .Staff_Page{
        grid-template-rows:  75px  auto;
    .doc_cards{
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas: 
        "director01 director02 ";
       

    }
   
    .director01{
        grid-area: director01;
    }
    .director02{
        grid-area: director02;
    }
    }
}

@media (min-width: 2500px) {
    .Staff_Page{
        grid-template-rows:  75px  auto;
    }
}