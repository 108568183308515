.TreatmentQuicklinks{
    grid-area: TreatmentQuicklinks;
    height: 100%;
    width: 100%;
  // background-color: #359c8d;
   border-radius: 15px;
    //background: rgb(1,139,141);
   // background: linear-gradient(45deg, rgba(1,139,141,1) 0%, rgb(46, 156, 142) 100%);
   display: grid;
  //  grid-template-columns: 1fr 1fr 1fr ;
    grid-template-rows: auto auto;
    grid-template-areas: 
    "title  ";
    .title{
        grid-area: title;
        text-align: center;
    }
    gap: 25px;
    .treatment{
        h1{
            font-size: 15px;
        }
        box-shadow: 0px 0px 35px -9px rgba(0,0,0,0.33);
        border-radius: 15px;
        border: #359c8d solid 1.5px;
 
        text-align: center;
        font-family:'darkmode-on';
        height: 100%;
        width:  100%;
        color: #359c8d;
        transition: .5s;
        img{
            background-color: white;
   //     box-shadow: 0px 0px 35px -9px rgba(0,0,0,0.33);

            border-radius: 50%;
        }
        &.nocontent{
            border: none;
        }
        &:hover{
    transform: scale(1.1); // scaling the item on hover

            background-color: #359c8d;
            color: white;
        }
//background-color: blueviolet;
    }

  
}

@media (min-width:768px) {
        .TreatmentQuicklinks{
            .treatment{
                h1{
            font-size:1.5rem;

                }
            }
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-areas: 
            "title  title title "
          ;
        }
    }