:root {
    --footer-bg: #359c8d;
    --color: #000000;
    --color-smooth: #161616;
    --input-border: #000000;
    --btn-bg-color: #f1f2fc;
    --btn-hover-bg: #d5d9f6;
    --btn-color: #000000;
  }
  
  footer {


    font-size: 16px;
    box-shadow: 0px 0px 35px -9px rgba(0,0,0,0.33);
    border-top: 3px solid #359c8d;
  //  background: var(--footer-bg);
    padding: 2em 4em;
    display: flex;
    align-items: start;
    justify-content: center;
    gap: 1em;
    //position: absolute;
  //  font-family: "IBM Plex Sans", sans-serif;
  }
  
  footer ul {
    list-style: none;
  }
  
  .col {
    flex: 1;
    
  }
  
  .col-title {
    
   // font-family: "Merriweather", serif;
    color: var(--color);
    font-size: 1.1em;
    font-weight: 500;
    padding: 0.5em 0;
  }
  
  .col-list a {
    color: var(--color-smooth);
    text-decoration: none;
    line-height: 1.4;
    font-weight: 400;
  }
  
  .col-list a:hover,
  .col-list a:focus {
    text-decoration: underline;
  }
  
  .subscribe-form,
  .social-media {
    display: grid;
    max-width: 150px;
    gap: 0.5em;
    padding: 0.5em 0;
  }
  
  .subscribe-input {
   /* width: auto;
    flex: 1;
    background: transparent;
    border: 0.06em solid var(--input-border);
    font-size: 1em;
    font-weight: 500;
    padding: 0.5em;
    border-radius: 0.3em;
    transition: all 0.25s ease 0s;
    display: inline-block;
    color: var(--color-smooth);*/
  }
  
  .subscribe-input:focus {
    outline: none;
    border-color: var(--color);
    color: var(--color);
  }
  
  .subscribe-btn {
  /*  background: var(--btn-bg-color);
    border: none;
    font-size: 1em;
    font-weight: 600;
    padding: 0.5em;
    border-radius: 0.3em;
    transition: all 0.25s ease 0s;
    display: inline-block;
    color: var(--btn-color);
    cursor: pointer;*/
  }
  
  .subscribe-btn:hover,
  .subscribe-btn:focus {
  //  background: var(--btn-hover-bg);
  }
  
  .form-title,
  .social-col-title {
    font-size: 1em;
    color: var(--color);
    font-weight: 500;
  }
  
  .social-media {
    flex-direction: column;
  }
  
  .social-media-list {
    display: flex;
    gap: 0.5rem;
  }
  
  .social-media-list a {
    padding: 0.5em;
    color: var(--color-smooth);
    display: flex;
    border-radius: 0.3em;
    transition: all 0.25s ease 0s;
  }
  
  .social-media-list a:hover,
  .social-media-list a:focus {
    color: var(--color);
    background: #ffffff1a;
  }
  
  .social-media-list svg {
    width: 1.5em;
    height: 1.5em;
    transition: all 0.25s ease 0s;
  }
  
  @media screen and (max-width: 750px) {
    footer {
      padding: 2em;
      flex-direction: column;
    }
  }
  