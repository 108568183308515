@import url(//fonts.googleapis.com/css?family=Lato:300:400);

body {
margin:0;
}
.card{
box-shadow: 0px 0px 35px -9px rgba(0,0,0,0.33);

padding: 50px;
text-align: center;
border-radius: 15px;
background-color: aliceblue;
color: #359c8d;
}


.header {
position:relative;
text-align:center;
background-size: cover;
background-position: center;
color:white;
.inner-header {
    height:65vh;
    width:100%;
    margin: 0;
    padding: 0;
    }
    
.flex { /*Flexbox for containers*/
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    }

    .waves {
        position:relative;
        width: 100%;
        height:15vh;
        margin-bottom:-7px; /*Fix for safari gap*/
        min-height:100px;
        max-height:150px;
        }
}









/* Animation */

.parallax > use {
animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
}
.parallax > use:nth-child(1) {
animation-delay: -2s;
animation-duration: 7s;
}
.parallax > use:nth-child(2) {
animation-delay: -3s;
animation-duration: 10s;
}
.parallax > use:nth-child(3) {
animation-delay: -4s;
animation-duration: 13s;
}
.parallax > use:nth-child(4) {
animation-delay: -5s;
animation-duration: 20s;
}
@keyframes move-forever {
0% {
transform: translate3d(-90px,0,0);
}
100% { 
transform: translate3d(85px,0,0);
}
}


.Contentwrapper{
padding-bottom: 100px;
position: relative;
top: 0;
left: 0;
right: 0;
bottom: 0;

margin: auto;
margin-top: 50px;

width: 90%;}
.cardwrapper{
    
    display: grid;
    grid-template-columns: auto ;
    grid-template-rows:auto ;
    grid-row-gap: 20px;
    grid-row-gap: 20px;

}
    .cardsplitcard{
        display: grid;
        box-shadow: 0px 0px 35px -9px rgba(0,0,0,0.33);
        grid-template-rows: auto ;
        grid-template-columns: 1fr  1fr;

padding: 50px;
text-align: left;
border-radius: 15px;
background-color: aliceblue;
color: #359c8d;
    }


/*Shrinking for mobile*/
@media (max-width: 768px) {
.waves {
height:40px;
min-height:40px;
}
.content {
height:30vh;
}
h1 {
font-size:24px;
}
.cardsplitcard{
display: grid;
grid-template-columns: auto;
        grid-template-rows: 1fr 1fr;
    }
}

@media (min-width: 2000px) {
.header {
width: 100vw;
object-fit: cover;
z-index: -1;
}
}



.contentone{
    width: 90%;
}

.content_two{
    width: 90%;
}