.doctor{
    width: 80%;
    margin: 0 auto;
  //  background-color: aquamarine;
    display: grid;
  //  margin-top: 150px;
//   margin-top: 100px;
  // margin-bottom: 100px;
   padding-top: 150px;
   padding-bottom: 150px;
    .card{
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas:
        "docimg"
        "doccontent"
        "Doc_Extras"
        ;
        &:hover{
            background-color:  #359c8d;
            transition: 0.5s;
            .docimg{

                img{
                    box-shadow: 0px 0px 35px -9px rgba(0,0,0,0.33);
                }
            }
            .Doc_Extras{
                color: white;
            }
            .doccontent{
                color: white;
                a{
              //   color: #000;
                }
                .outline{
                    -webkit-text-stroke: 1px rgb(255, 255, 255);
                    color: #359c8d;
                }
            }
            h2{
                color: white;
            }
            h1{
                color: white;
            }
           
           
        }
    transition: 0.5s;

        display: grid;
        box-shadow: 0px 0px 35px -9px rgba(0,0,0,0.33);
        border-radius: 30px;
        width: 100%;
        
        text-align: center;
        background-color: rgb(241, 241, 241);
        .docimg{
            grid-area: docimg;
            place-self: center;
            img{
                    box-shadow: 0px 0px 35px -9px rgba(0,0,0,0.33);
                    border: 3px solid  rgb(241, 241, 241);
                border-radius: 360px;
                margin:  50px auto;
                display: block;
                width: 150px;
            }
        }.Doc_Extras{
            grid-area: Doc_Extras;
            color: black;
            //display: none;
            width: 95%;
            margin: 0 auto;
            text-align: left;
        }
       .doccontent{
            grid-area: doccontent;
            padding-bottom: 15px;
        width: 80%;
        margin: auto;
        text-align: left;
        h2{
            margin: 0;
               font-family: darkmode-on;
               font-weight: 900;
               font-size: 15px;
           }
           .outline{
               -webkit-text-stroke: 1px rgb(0, 0, 0);
               color: transparent;
           }
           h1{
            margin: 0;
            font-size: 12px;
            font-family: darkmode-on;
            font-weight: 900;
           
           
           }
           a{
            margin: 0;
            font-family: darkmode-on;
            font-size: 12px;
            font-weight: 900;
            color: rgb(0, 70, 128);
            word-wrap: break-word;
        }
       }
        padding: 0;
    }
}

@media (min-width: 600px) {
    .doctor{
        
        .card{
          display: grid;
            grid-template-columns: 1fr 1fr ;
            grid-template-rows: 1fr 1fr ;
            grid-template-areas: 
            "doccontent docimg"
            "Doc_Extras Doc_Extras";
            ;
            .docimg{
                img{
                    width: 200px;
                }
            }
            .doccontent{
               
                h2{
                       font-size: 30px;
                   }
                   .outline{
                       -webkit-text-stroke: 2px rgb(0, 0, 0);
                   }
                   h1{
                    font-size: 15px;
                   }
                   a{
                    font-size: 15px;
                   
                }
               }
        }
    }
    }
    @media (min-width: 1366px) {
        .doctor{
    
            grid-template-columns: auto auto ;
            grid-template-rows: auto ;
           // gap: 2.5%;
            place-items: center;
        }
    
    }
    @media (min-width: 2500px) {
        .doctorPagewrapper{
            height: 100Vh;
        }
            .doctor{
        .card{
           // grid-template-columns: 1fr 1fr 1fr ;
            .Doc_Extras{
                margin: 0;
                font-family: darkmode-on;
              //  font-weight: 900;
                font-size: 15px;
                display: block;
                width: 80%;
                margin: auto;
                text-align: center;
            }
        }
    }
    }
    