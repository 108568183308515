
.Callback_request{
        text-align: center;
        align-items: center;
form{
    h3{
        font-family: darkmode-on,sans-serif;
        font-weight: 900;
    }
    display: grid;
    grid-template-rows: auto auto auto auto ;
    width: 80%;
    margin: auto;
    margin-top: 10%;
    margin-bottom: 10%;

}

}

@media (min-width: 600px) {
    .Callback_request{
        form{
            width: 60%;
        }
    }
 ;
}
@media (min-width: 1366px){
    .Callback_request{
        form{
            width: 50%;
        }
    }
}
@media (min-width: 2500px) {
    .Callback_request{
        form{
            width: 550px;
        }
    }
}