.aboutus{
    text-align: center;
    padding: 25px 5px;
    width: 80%;
    display: grid;
    grid-template-rows: auto auto auto;
    margin: 0 auto;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 35px -9px rgba(0,0,0,0.33);
    border-radius: 15px;
    .heading{
        text-align: center;
    }
    .Branches_Main{
        grid-area: Branches_Main;
    }
    grid-template-areas: 
    "heading detail"
    "Branches_Main Branches_Main"
    ;
}
@media (min-width:768px) {
    .aboutus{
        grid-template-columns: 1fr 1fr;
        .heading{
            text-align: center;
            margin: auto 0;
        }
    }

}