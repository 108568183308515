.voucher{
  //  height: 100vh;
    padding-top: 100px;
    margin-bottom: 50px;
    width: 80%;
    display: grid;
    grid-template-columns: auto ;
    grid-template-rows: .75fr  auto;
    grid-template-areas: "voucherdetials"
    "invoiceitems";
    margin: auto;
    .voucherdetials{
        grid-area: voucherdetials;
    width: 80%;
        margin: auto;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr auto;
        grid-template-areas: "description itemcount total"
        "voucheritems voucheritems voucheritems";
        .total{
            grid-area: total;
text-align: right;  
            h1{
             margin: auto;
            }
        }
        .itemcount{
            grid-area: itemcount;
text-align: right;  

        }
        .voucheritems{
            grid-area: voucheritems;
            margin: auto;
        }
        .description{

            grid-area: description;
        }
    }
    .invoiceitems{
        grid-area: invoiceitems;
      //  background-color: aquamarine;
        margin: auto;
    }

}

@media (min-width: 600px) {
  .voucher{
    grid-template-rows: .05fr  auto;
  }
}

@media (min-width: 1366px) {
    .voucher{
      grid-template-rows: 1fr  auto;
    }
  }

  
@media (min-width: 2500px) {
    .voucher{
      grid-template-rows: 75px  auto;
    }
  }