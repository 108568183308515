body{
    display: grid;

    .career_wrapper{
        display: grid;
        width: 80%;
        margin: auto;
        grid-template-columns:  auto;
        grid-template-rows:  1fr  ;
    }

    .Careersubmittion{
        max-width: 90%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;

       
    }
}
