
div.comparison { 
  margin: 15px;
    width: 60vw;
    height: 60vw;
    max-width: 300px;
    max-height: 300px;
    overflow: hidden; 
    border-radius: 15px;
    box-shadow: 0px 0px 35px -9px rgba(0,0,0,0.33);

}
  div.comparison figure { 
  background-size: cover;
    position: relative;
    font-size: 0;
    width: 100%; 
    height: 100%;
    margin: 0; 

  }
  div.comparison figure > img { 
    position: relative;
    width: 100%;
  }
  div.comparison figure div { 
   background-size: cover;
    position: absolute;
    width: 50%; 
    overflow: hidden;
    bottom: 0;
    height: 100%;
border-right: #359c8d 5px solid;
 
  }
  
  input[type=range]{
    -webkit-appearance:none;
    -moz-appearance:none;
    position: relative;
    top: -100%;
   // left: -1.5%;
   height: 100%;
//background-color: #359c8d2a;
    width: 100%; 
    margin: auto;
    border: none;
    &:hover{
        border: none;
    }
  }
  input[type=range]:focus { 
    outline: none; 
  }
  input[type=range]:active { 
    outline: none;  
  }
  

  input[type=range]::active { 
    border: none; 
    outline: none;
  }
  input[type=range]::-webkit-slider-thumb {
-webkit-appearance:none;
width: 15px;
     }
  input[type=range]::-moz-range-thumb {
    -moz-appearance: none;
    width: 20px;
    height: 50%;

    background: #fff;
       }   

       
  input[type=range]:focus::-webkit-slider-thumb {
    background-color:  #359c8d;
     }
  input[type=range]:focus::-moz-range-thumb {
    background-color:  #359c8d;
     }
