
.Faq_Wrapper{
    position: relative;
    min-height: 100vh;
    
    width: 95%;
    margin: auto;
    padding-top: 150px;
    .Faq{
        text-align: left;
     
       padding: 50px;
        .question{
            h1{
                font-size: 14px;
                padding: 15px;
            }
        }
        .answer{
            p{
                font-size: 12px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
              //  -webkit-line-clamp: 2; /* number of lines to show */
                -webkit-box-orient: vertical;
                visibility: visible;
            }
        }
    }
    
}
@media (min-width: 600px) {
    .Faq_Wrapper{
        width: 90%;
        .Faq{
            border:  3px solid #66be9d;
            border-radius: 15px;
            box-shadow: 0px 0px 35px -9px rgba(0,0,0,0.33);
            text-align: center;
     
            .question{
                h1{
                    font-size: 16px;
                }
            }
            .answer{
                p{
                font-size: 13px;

                }
            }
        }
        
    }
}
@media (min-width: 1366px) {
    .Faq_Wrapper{
        width: 80%;
        .Faq{
            text-align: center;

            .question{
                h1{
                    font-size: 18px;
                }
            }

            .answer{
                p{
                    font-size: 14px;
    
                }
            }
        }
        
    }
}
@media (min-width: 2500px) {
    .Faq_Wrapper{

        .Faq{
            .question{
                h1{
                    font-size: 20px;
                }
            }
            .answer{
            }
        }
        
    }
}