*{
    margin: 0;
    padding: 0;
}

body,html{
    height: 100%;
}
body{
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.background{
    width: 100vw;
    height: 100vh;
    #BackgroundVid{
        width: 100vw;
        height: 100vh;
        object-fit: cover;
        position: absolute;
        z-index: -1;
    }
}
.Landing_Image{
    /* The image used */
background-image: url(https://cdn.havelockdental.com/Havelock%20Dental%20Landing%20Page.jpg);

 /* Full height */
 height: 100%;

 /* Center and scale the image nicely */
 background-position: center;
 background-repeat: no-repeat;
 background-size: cover;

 position: relative;
 text-align: center;
}
.centered {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;

    color: #FFF;
    color :#359c8d;
    //color :#000;
    h4{
        font-size:18px;
        font-family: darkmode-on,sans-serif;
        font-weight: 900;
        font-style: normal;
       // -webkit-text-stroke: 1px rgb(0, 0, 0);
        -webkit-text-stroke: 1px rgb(255, 255, 255);
    }
    h1{
        font-size:15px;
        -webkit-text-stroke: .25px rgb(255, 255, 255);

        //-webkit-text-stroke: .75px rgb(0, 0, 0);

    }
  }

@media (min-width: 600px) {
    .centered {
            h4{
                font-size: 35px;

            }
            h1{
                font-size:25px;
        
            }
        }
}

@media (min-width: 1366px) {
  
    .centered {
        h4{
            font-size: 75px;
        }
        h1{
            font-size:30px;
    
        }
    }
    
}
@media (min-width: 2500px) {
    .centered {
        h4{
            font-size: 75px;

        }
    }
}

