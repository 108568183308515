.Brouchers_Page {
  height: auto;
  min-height: 100vh;
  margin-top: 150px;
}

.Brouchers_Page .Brouchers_Page_title {
  text-align: center;
}

.Brouchers_Page .Brouchers_Wrapper {
  display: -ms-grid;
  display: grid;
  place-content: center;
  -ms-grid-columns: (250px)[auto-fill];
      grid-template-columns: repeat(auto-fill, 250px);
  gap: 10px;
}

.Brouchers_Page .Brouchers_Wrapper .Broucher {
  -webkit-transition: 1s ease-in;
  transition: 1s ease-in;
  border: 1px solid #0a68873b;
  color: black;
  border: 1px solid #359c8d;
  max-width: 250px;
  margin: 15px;
  border-radius: 10px;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 50px 1fr 50px;
      grid-template-rows: 50px 1fr 50px;
      grid-template-areas: "Broucher_title" "Broucher_Image" "Broucher_reader";
}

.Brouchers_Page .Brouchers_Wrapper .Broucher:hover {
  -webkit-box-shadow: 0px 11px 38px -8px rgba(0, 0, 0, 0.39);
          box-shadow: 0px 11px 38px -8px rgba(0, 0, 0, 0.39);
  border: 1px solid #0a6887c5;
  z-index: 100;
  -webkit-transition: .25s ease-out;
  transition: .25s ease-out;
  scale: 1.01;
}

.Brouchers_Page .Brouchers_Wrapper .Broucher .Broucher_title {
  display: -ms-grid;
  display: grid;
  place-content: center;
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: Broucher_title;
  text-align: center;
  width: 100%;
  font-weight: 800;
}

.Brouchers_Page .Brouchers_Wrapper .Broucher .Broucher_Image {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  grid-area: Broucher_Image;
  display: -ms-grid;
  display: grid;
  place-content: center;
  margin: 0 auto;
}

.Brouchers_Page .Brouchers_Wrapper .Broucher .Broucher_Image img {
  max-width: 90%;
  margin: 0 auto;
  background-size: cover;
}

.Brouchers_Page .Brouchers_Wrapper .Broucher .Broucher_reader {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  grid-area: Broucher_reader;
  text-align: center;
  display: -ms-grid;
  display: grid;
  place-content: center;
}
