.Brouchers_Page{
    height: auto;
    min-height: 100vh;
    margin-top: 150px;
.Brouchers_Page_title{
    text-align: center;

}
    .Brouchers_Wrapper{
        display: grid;
      //  background-color: bisque;
      
place-content: center;
        grid-template-columns: repeat(auto-fill, 250px);
        gap: 10px;
        .Broucher{
            transition: 1s ease-in;
            border: 1px solid #0a68873b;
            &:hover{
                //  background-color: #0a6887;
          
          
             // background-color: #0b549425;
            
              box-shadow: 0px 11px 38px -8px rgba(0,0,0,0.39);
              border: 1px solid #0a6887c5;
            
              z-index: 100;
            transition: .25s ease-out;
            
            scale: 1.01;
              }
              
            color: black;
          //  background-color: beige;
border: 1px solid #359c8d;
            max-width: 250px;
            margin: 15px;
            border-radius: 10px;


            display: grid;

            grid-template-rows: 50px 1fr 50px;

            grid-template-areas: 
            "Broucher_title"
            "Broucher_Image"
            "Broucher_reader"
            ;
.Broucher_title{
    display: grid;
    place-content: center;
    grid-area: Broucher_title;
text-align: center;
width: 100%;
font-weight: 800;

}
.Broucher_Image{
    grid-area: Broucher_Image;
display: grid;
place-content: center;
margin: 0 auto; 
img{
max-width: 90%;
margin: 0 auto; 

        background-size: cover;
    }

}
            .Broucher_reader{
                grid-area: Broucher_reader;
text-align: center;
display: grid;
place-content: center;
            }
        }

    }
}