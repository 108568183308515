@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


*{
  font-family: 'Poppins', sans-serif;
}
.contentBx{
    font-family:'darkmode-on';

}
/*
body{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #131313;
}*/


.container{
  position: relative;

}
.Short_Description{
    p{
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      -webkit-box-orient: vertical;
      visibility: visible;
    }
  }
  
.container .card{
  box-shadow: 0px 0px 35px -9px rgba(0,0,0,0.33);
  border: #359c8d solid 2px;
  position: relative;
  width: 100%;
  max-width: 350px;

  height: 450px;
//  background: #232323;
 // background: #66be9d;
  background: #FFF;
  border-radius: 20px;
  overflow: hidden;

}

.container .card:before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #359c8d;
  clip-path: circle(150px at 80% 20%);
  transition: 0.5s ease-in-out;
}

.container .card:hover:before{
  clip-path: circle(300px at 80% -20%);
}


.container{
    .card{
        
        &:after{
           // content: 'Implant';
           content: 'Dental';
            position: absolute;
            top: 30%;
            left: -20%;
            font-size: 7em;
            font-weight: 800;
            font-style: italic;
           // color: rgba(67, 216, 159, 0.589)
            color: rgba(113, 255, 220, 0.589)
        }
    }
    
}

.container .card .imgBx{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  width: 100%;
  height: 220px;
  transition: 0.5s;
}

.container .card:hover .imgBx{
  top: 0%;
  transform: translateY(0%);
    
}

.container .card .imgBx img{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) ;
 // transform: translate(-50%, -50%) rotate(-25deg);
  // width: 270px;
  width: 100px;
}

.container .card .contentBx{
   
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  text-align: center;
  transition: 1s;
  z-index: 4;
}

.container .card:hover .contentBx{
  height: 210px;
}

.container .card .contentBx h2{
  position: relative;
  font-weight: 600;
  letter-spacing: 1px;
  color: #359c8d;
  margin: 0;
}

.container .card .contentBx .Short_Description{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  transition: 0.5s;opacity: 0;
  visibility: hidden;
  padding-top: 0;
  padding-bottom: 0;
}

.container .card:hover .contentBx .Short_Description{
  opacity: 1;
  visibility: visible;
  transition-delay: 0.5s;
}

.container .card:hover .contentBx .color{
  opacity: 1;
  visibility: visible;
  transition-delay: 0.6s;
}

.container .card .contentBx .Short_Description{
  p{
    color: #359c8d;
  
    }
}
.container .card .contentBx .Short_Description h3{
  color: #359c8d;
  font-weight: 300;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-right: 10px;
}

.container .card .contentBx .Short_Description span{
  width: 26px;
  height: 26px;
  text-align: center;
  line-height: 26px;
  font-size: 14px;
  display: inline-block;
  background: #fff;
  margin: 0 5px;
  transition: 0.5s;
  color: rgb(0, 0, 0);
  border-radius: 4px;
  cursor: pointer;
 
}




.container .card .contentBx a{
  display: inline-block;
  padding: 10px 20px;
  background: #359c8d;
  border-radius: 4px;
  margin-top: 10px;
  text-decoration: none;
  font-weight: 600;
  color: #FFF;
  opacity: 0;
  transform: translateY(50px);
  transition: 0.5s;
  margin-top: 0;
}

.container .card:hover .contentBx a{
  opacity: 1;
  transform: translateY(0px);
  transition-delay: 0.75s;
  
}

