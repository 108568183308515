body{
    .inventorysubmitwrapper{
        max-width: 90%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;

       
    }
}