/*.Summery_Wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    grid-template-rows: repeat(auto-fit, minmax(30%, 1fr));
    width: 80%;
    max-width: 100vw;
    margin: 150px auto;
    
    @media screen and (min-width: 1800px) {
    grid-template-columns: repeat(5, minmax(250px, 1fr));
    }
    }
    
    
    .Summery_Wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    width: 80%;
    max-width: 100vw;
    margin: 150px auto;
    }
    
    @media screen and (min-width: 1800px) {
    .Summery_Wrapper {
    grid-template-columns: repeat(3, minmax(250px, 1fr));
    grid-template-rows: repeat(auto-fit, minmax(250px, 1fr));
    }
    }*/

    .Summery_Wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        //grid-template-rows: repeat(auto-fill, minmax(250px, 1fr));
        grid-template-rows: repeat(10, 250px);

        grid-gap: 20px;
        justify-content: center;
        align-items: center;
        width: 80%;
        max-width: 100vw;
        margin: 150px auto;
      }
      