.Branches_Page {
  height: 100vh;
  margin-top: 150px;
  margin: 0 auto;
}

.Branches_Wrapper {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(100px, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  width: 100%;
  margin: 0 auto;
  justify-items: center;
  place-items: center;
}

.Branches_Wrapper .Branch {
  position: relative;
  width: 320px;
  /* Set the width and height to match the image size */
  height: 240px;
  background-size: cover;
  /* Adjust the sizing as needed */
  background-repeat: no-repeat;
  border-radius: 15px;
  -webkit-transition: .5s ease;
  transition: .5s ease;
}

.Branches_Wrapper .Branch:hover {
  -webkit-box-shadow: 0px 0px 35px -9px rgba(0, 0, 0, 0.342);
          box-shadow: 0px 0px 35px -9px rgba(0, 0, 0, 0.342);
  -webkit-transition: .5s ease;
  transition: .5s ease;
  scale: 1.01;
}

.Branches_Wrapper .Branch:hover::before {
  opacity: 0;
  /* On hover, make the overlay visible */
}

.Branches_Wrapper .Branch::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #66be9d;
  /* Adjust the overlay color and opacity as needed */
  border-radius: 15px;
  opacity: .5;
  /* Initially, make the overlay transparent */
  -webkit-transition: opacity .5s ease;
  transition: opacity .5s ease;
}

.Branches_Wrapper .Branch .Location {
  position: absolute;
  top: 50%;
  /* Center vertically */
  left: 50%;
  /* Center horizontally */
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  /* Center the text precisely */
  background-color: #66be9d;
  /* Optional background color with opacity for readability */
  padding: 10px;
  /* Optional padding for the text */
  color: white;
  font-weight: 700;
}

@media (max-width: 768px) {
  .Branches_Wrapper {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    /* Set a single column layout for mobile */
    margin-top: 150px;
    /* Adjust the margin-top for spacing */
  }
}
