table {
    
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 50%;
    border-radius: 15px 15px 0 0;

    margin: auto;
    th {
        text-align: center;
        padding: 8px;
        color: #359c8d;
      }
      td{
       
        text-align: center;

        border-bottom: 2px solid #359c8d;

      }
      tr{
        &:hover{
          background-color: #359c8d;
          color: white;
        }
      }
      tr:nth-child(even) {
background-color: rgb(207, 207, 207);
&:hover{
  background-color: #359c8d;
  color: white;

}
      }
      tr:last-child {
       // border-radius: 15px;

         }
       
th{
  background:#FFF; 
}
  }


  .voucherviewwrapper{
    .voucher{
      table {
        width: 100%;
      }
    }
    .Paymentvoucherprint{
      table {
        width: 100%;
        tr:nth-child(even) {
          background-color: rgb(255, 255, 255);
          &:hover{
            background-color: #359c8d;
            color: white;
          }
                }
      }

    }
  }
  
