@import url("https://use.typekit.net/rnd3nrr.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url(//fonts.googleapis.com/css?family=Lato:300:400);
a {
  text-decoration: none;
}

.sociallinks {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -ms-grid-rows: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
  width: 80%;
  margin: auto;
  gap: 2.5%;
}

.sociallinks .title {
  text-align: center;
}

.sociallinks .title h4 {
  margin: 15px auto;
  font-family: darkmode-on;
  font-weight: 900;
  font-size: 15px;
}

.sociallinks .links {
  place-items: center;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
  gap: 2.5%;
}

.sociallinks a img {
  width: 25px;
}

.sociallinks .review {
  text-align: center;
}

.sociallinks .review h4 {
  margin: 15px auto;
  font-family: darkmode-on;
  font-weight: 900;
  font-size: 15px;
}

.hotline {
  background-color: white;
  border-radius: 15px;
  -webkit-box-shadow: 0px 0px 35px -9px rgba(0, 0, 0, 0.33);
          box-shadow: 0px 0px 35px -9px rgba(0, 0, 0, 0.33);
  padding: 20px;
  width: 50%;
  text-align: center;
  margin: 0 auto;
}

.hotline h1 {
  text-decoration: underline;
}

.hotline a {
  color: var(--color-smooth);
  text-decoration: none;
  line-height: 1.4;
  font-weight: 600;
  font-size: 30px;
}

.hotline a:hover,
.hotline a:focus {
  text-decoration: underline;
}

.Quicklinks {
  margin-bottom: 25px;
}

.Quicklinks .doc_cards {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: auto;
      grid-template-rows: auto;
  width: 80%;
}

@media (min-width: 600px) {
  .sociallinks .links a img {
    width: 50px;
  }
  .sociallinks .review h4 {
    font-size: 20px;
  }
}

@media (min-width: 1366px) {
  .sociallinks .links a img {
    width: 75px;
  }
}

.Faq_Wrapper {
  position: relative;
  min-height: 100vh;
  width: 95%;
  margin: auto;
  padding-top: 150px;
}

.Faq_Wrapper .Faq {
  text-align: left;
  padding: 50px;
}

.Faq_Wrapper .Faq .question h1 {
  font-size: 14px;
  padding: 15px;
}

.Faq_Wrapper .Faq .answer p {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  visibility: visible;
}

@media (min-width: 600px) {
  .Faq_Wrapper {
    width: 90%;
  }
  .Faq_Wrapper .Faq {
    border: 3px solid #66be9d;
    border-radius: 15px;
    -webkit-box-shadow: 0px 0px 35px -9px rgba(0, 0, 0, 0.33);
            box-shadow: 0px 0px 35px -9px rgba(0, 0, 0, 0.33);
    text-align: center;
  }
  .Faq_Wrapper .Faq .question h1 {
    font-size: 16px;
  }
  .Faq_Wrapper .Faq .answer p {
    font-size: 13px;
  }
}

@media (min-width: 1366px) {
  .Faq_Wrapper {
    width: 80%;
  }
  .Faq_Wrapper .Faq {
    text-align: center;
  }
  .Faq_Wrapper .Faq .question h1 {
    font-size: 18px;
  }
  .Faq_Wrapper .Faq .answer p {
    font-size: 14px;
  }
}

@media (min-width: 2500px) {
  .Faq_Wrapper .Faq .question h1 {
    font-size: 20px;
  }
}

body .inventorysubmitwrapper {
  max-width: 90%;
  margin: 0 auto;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -ms-grid-rows: auto;
      grid-template-rows: auto;
}

* {
  margin: 0;
  padding: 0;
}

body, html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.background {
  width: 100vw;
  height: 100vh;
}

.background #BackgroundVid {
  width: 100vw;
  height: 100vh;
  -o-object-fit: cover;
     object-fit: cover;
  position: absolute;
  z-index: -1;
}

.Landing_Image {
  /* The image used */
  background-image: url(https://cdn.havelockdental.com/Havelock%20Dental%20Landing%20Page.jpg);
  /* Full height */
  height: 100%;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  text-align: center;
}

.centered {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 5;
  color: #FFF;
  color: #359c8d;
}

.centered h4 {
  font-size: 18px;
  font-family: darkmode-on,sans-serif;
  font-weight: 900;
  font-style: normal;
  -webkit-text-stroke: 1px white;
}

.centered h1 {
  font-size: 15px;
  -webkit-text-stroke: 0.25px white;
}

@media (min-width: 600px) {
  .centered h4 {
    font-size: 35px;
  }
  .centered h1 {
    font-size: 25px;
  }
}

@media (min-width: 1366px) {
  .centered h4 {
    font-size: 75px;
  }
  .centered h1 {
    font-size: 30px;
  }
}

@media (min-width: 2500px) {
  .centered h4 {
    font-size: 75px;
  }
}

html {
  margin: 0;
}

.content_home {
  width: 90%;
  margin: auto;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: auto;
      grid-template-rows: auto;
      grid-template-areas: "livecount" "contact" "directors" "aboutus" "Branches_Wrapper" "beforeafter" "TreatmentQuicklinks" "HomeFAQ";
  gap: 25px;
}

.content_home .Branches_Wrapper {
  -ms-grid-row: 5;
  -ms-grid-column: 1;
  grid-area: Branches_Wrapper;
}

.content_home .TreatmentQuicklinks {
  -ms-grid-row: 7;
  -ms-grid-column: 1;
  grid-area: TreatmentQuicklinks;
}

.content_home .HomeFAQ {
  -ms-grid-row: 8;
  -ms-grid-column: 1;
  grid-area: HomeFAQ;
  height: 100%;
  width: 100%;
}

.content_home .contact {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  grid-area: contact;
}

.content_home .livecount {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: livecount;
}

.content_home .beforeafter {
  margin: 0 auto;
  display: -ms-grid;
  display: grid;
  -ms-grid-row: 6;
  -ms-grid-column: 1;
  grid-area: beforeafter;
  margin: 15px;
  place-items: center;
  -ms-grid-rows: auto auto;
      grid-template-rows: auto auto;
}

.content_home .aboutus {
  -ms-grid-row: 4;
  -ms-grid-column: 1;
  grid-area: aboutus;
  width: 100%;
}

.content_home .directors {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  grid-area: directors;
  width: 100%;
}

@media (min-width: 768px) {
  .content_home {
    -ms-grid-columns: auto auto;
        grid-template-columns: auto auto;
    -ms-grid-rows: auto auto auto auto auto auto;
        grid-template-rows: auto auto auto auto auto auto;
        grid-template-areas: "contact livecount" "directors directors" "aboutus aboutus" "Branches_Wrapper Branches_Wrapper" "beforeafter beforeafter" "TreatmentQuicklinks TreatmentQuicklinks" "HomeFAQ HomeFAQ";
  }
  .content_home .beforeafter {
    margin: 0 auto;
    -ms-grid-row: 5;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-area: beforeafter;
    display: -ms-grid;
    display: grid;
    margin: 15px;
    place-items: center;
    -ms-grid-columns: auto auto;
        grid-template-columns: auto auto;
  }
  .content_home .TreatmentQuicklinks {
    -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas: "title  title title ";
  }
}

.doc_cards {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: auto;
      grid-template-rows: auto;
  width: 100%;
  margin: 0 auto;
  gap: 15px;
  margin-top: 2.5%;
  margin-bottom: 2.5%;
}

.doc_cards .card_doc {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  display: -ms-grid;
  display: grid;
  -webkit-box-shadow: 0px 0px 35px -9px rgba(0, 0, 0, 0.33);
          box-shadow: 0px 0px 35px -9px rgba(0, 0, 0, 0.33);
  border-radius: 30px;
  width: 100%;
  text-align: center;
  background-color: #f1f1f1;
}

.doc_cards .card_doc:hover {
  background-color: #359c8d;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.doc_cards .card_doc:hover .docimg img {
  border: 3px solid #FFF;
  -webkit-box-shadow: 0px 0px 35px -9px rgba(0, 0, 0, 0.33);
          box-shadow: 0px 0px 35px -9px rgba(0, 0, 0, 0.33);
}

.doc_cards .card_doc:hover .Doc_Extras {
  color: white;
}

.doc_cards .card_doc:hover .doccontent {
  color: white;
}

.doc_cards .card_doc:hover .doccontent .outline {
  -webkit-text-stroke: 1px white;
  color: #359c8d;
}

.doc_cards .card_doc:hover h2 {
  color: white;
}

.doc_cards .card_doc:hover h1 {
  color: white;
}

.doc_cards .card_doc .docimg {
  place-self: center;
}

.doc_cards .card_doc .docimg img {
  border: 3px solid #f1f1f1;
  border-radius: 360px;
  margin: 50px auto;
  display: block;
  width: 100px;
}

.doc_cards .card_doc .Doc_Extras {
  display: none;
  width: 80%;
  margin: auto;
  text-align: center;
}

.doc_cards .card_doc .Doc_Extras p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  /* number of lines to show */
  -webkit-box-orient: vertical;
  visibility: visible;
}

.doc_cards .card_doc .doccontent {
  padding-bottom: 15px;
  width: 80%;
  margin: auto;
  text-align: center;
}

.doc_cards .card_doc .doccontent h2 {
  margin: 0;
  font-family: darkmode-on;
  font-weight: 900;
  font-size: 15px;
}

.doc_cards .card_doc .doccontent .outline {
  -webkit-text-stroke: 1px black;
  color: transparent;
}

.doc_cards .card_doc .doccontent h1 {
  margin: 0;
  font-size: 12px;
  font-family: darkmode-on;
  font-weight: 900;
}

.doc_cards .card_doc .doccontent a {
  margin: 0;
  font-family: darkmode-on;
  font-size: 12px;
  font-weight: 900;
  color: #004680;
  word-wrap: break-word;
}

@media (min-width: 600px) {
  .doc_cards .card_doc .docimg img {
    width: 200px;
  }
  .doc_cards .card_doc .doccontent {
    text-align: left;
  }
  .doc_cards .card_doc .doccontent h2 {
    font-size: 30px;
  }
  .doc_cards .card_doc .doccontent .outline {
    -webkit-text-stroke: 2px black;
  }
  .doc_cards .card_doc .doccontent h1 {
    font-size: 15px;
  }
  .doc_cards .card_doc .doccontent a {
    font-size: 15px;
  }
}

@media (min-width: 1366px) {
  .doc_cards {
    -ms-grid-columns: auto auto;
        grid-template-columns: auto auto;
    -ms-grid-rows: auto;
        grid-template-rows: auto;
    place-items: center;
  }
}

@media (min-width: 2500px) {
  .doc_cards .card_doc {
    -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
  }
  .doc_cards .card_doc .Doc_Extras {
    text-align: left;
    margin: 0;
    font-family: darkmode-on;
    font-weight: 900;
    font-size: 15px;
    display: block;
    width: 80%;
    margin: auto;
  }
}

div.comparison {
  margin: 15px;
  width: 60vw;
  height: 60vw;
  max-width: 300px;
  max-height: 300px;
  overflow: hidden;
  border-radius: 15px;
  -webkit-box-shadow: 0px 0px 35px -9px rgba(0, 0, 0, 0.33);
          box-shadow: 0px 0px 35px -9px rgba(0, 0, 0, 0.33);
}

div.comparison figure {
  background-size: cover;
  position: relative;
  font-size: 0;
  width: 100%;
  height: 100%;
  margin: 0;
}

div.comparison figure > img {
  position: relative;
  width: 100%;
}

div.comparison figure div {
  background-size: cover;
  position: absolute;
  width: 50%;
  overflow: hidden;
  bottom: 0;
  height: 100%;
  border-right: #359c8d 5px solid;
}

input[type=range] {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  top: -100%;
  height: 100%;
  width: 100%;
  margin: auto;
  border: none;
}

input[type=range]:hover {
  border: none;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]:active {
  outline: none;
}

input[type=range]::active {
  border: none;
  outline: none;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 15px;
}

input[type=range]::-moz-range-thumb {
  -moz-appearance: none;
  width: 20px;
  height: 50%;
  background: #fff;
}

input[type=range]:focus::-webkit-slider-thumb {
  background-color: #359c8d;
}

input[type=range]:focus::-moz-range-thumb {
  background-color: #359c8d;
}

.Staff_Page {
  display: -ms-grid;
  display: grid;
  width: 80%;
  margin: auto;
  -ms-grid-columns: auto;
      grid-template-columns: auto;
  -ms-grid-rows: .75fr  auto;
      grid-template-rows: .75fr  auto;
  grid-area: "title" "doc_cards";
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
  min-height: 100vh;
}

.Staff_Page .title {
  width: 80%;
  margin: auto;
  margin-bottom: 25px;
}

.Staff_Page .title:hover {
  -webkit-transition: 1s;
  transition: 1s;
}

.Staff_Page .title:hover .outline {
  -webkit-transition: 1s;
  transition: 1s;
  -webkit-text-stroke: 1px white;
  color: #359c8d;
}

.Staff_Page .title h5 {
  text-align: center;
  font-size: 65px;
  color: #000;
  font-family: darkmode-on;
}

.Staff_Page .title .outline {
  -webkit-text-stroke: 1px black;
  color: transparent;
}

.Staff_Page .directors {
  display: -ms-grid;
  display: grid;
  width: 80%;
  margin: auto;
}

@media (min-width: 600px) {
  .Staff_Page {
    -ms-grid-rows: .05fr   auto;
        grid-template-rows: .05fr   auto;
  }
}

@media (min-width: 1366px) {
  .Staff_Page {
    -ms-grid-rows: 75px  auto;
        grid-template-rows: 75px  auto;
  }
  .Staff_Page .doc_cards {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    -ms-grid-rows: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas: "director01 director02 ";
  }
  .Staff_Page .director01 {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    grid-area: director01;
  }
  .Staff_Page .director02 {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    grid-area: director02;
  }
}

@media (min-width: 2500px) {
  .Staff_Page {
    -ms-grid-rows: 75px  auto;
        grid-template-rows: 75px  auto;
  }
}

.container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[6];
      grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: 100px 300px;
  grid-gap: 10px;
  grid-auto-flow: dense;
  padding-bottom: 50px;
  width: 80%;
  margin: auto;
  margin-top: 150px;
}

.gallery-item {
  width: 100%;
  height: 100%;
  position: relative;
}

.gallery-item .image {
  border-radius: 15px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.gallery-item .image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: 50% 50%;
     object-position: 50% 50%;
  cursor: pointer;
  -webkit-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
}

.gallery-item:hover .image img {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

.gallery-item .text {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: white;
  font-size: 25px;
  pointer-events: none;
  z-index: 4;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
  -webkit-backdrop-filter: blur(5px) saturate(1.8);
  backdrop-filter: blur(5px) saturate(1.8);
}

.gallery-item:hover .text {
  opacity: 1;
  -webkit-animation: move-down .3s linear;
          animation: move-down .3s linear;
  padding: 1em;
  width: 100%;
}

.w-1 {
  grid-column: span 1;
}

.w-2 {
  grid-column: span 2;
}

.w-3 {
  grid-column: span 3;
}

.w-4 {
  grid-column: span 4;
}

.w-5 {
  grid-column: span 5;
}

.w-6 {
  grid-column: span 6;
}

.h-1 {
  grid-row: span 1;
}

.h-2 {
  grid-row: span 2;
}

.h-3 {
  grid-row: span 3;
}

.h-4 {
  grid-row: span 4;
}

.h-5 {
  grid-row: span 5;
}

.h-6 {
  grid-row: span 6;
}

@media screen and (max-width: 500px) {
  .container {
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
  }
  .w-1, .w-2, .w-3, .w-4, .w-5, .w-6 {
    grid-column: span 1;
  }
  .h-1, .h-2, .h-3, .h-4, .h-5, .h-6 {
    grid-row: span 3;
  }
}

@-webkit-keyframes move-down {
  0% {
    top: 10%;
  }
  50% {
    top: 35%;
  }
  100% {
    top: 50%;
  }
}

@keyframes move-down {
  0% {
    top: 10%;
  }
  50% {
    top: 35%;
  }
  100% {
    top: 50%;
  }
}

.doctorPage {
  width: 90%;
  margin: 0 auto;
}

.doctorPage .doctor {
  margin: 0 auto;
  width: 90%;
}

.doctorPage .doctor .card {
  background-color: beige;
}

.doctorPage .doctor .card .docimg img {
  width: 150px;
  height: 150px;
}

.doctorPage .doctor .card .Doc_Extras {
  width: 95%;
  padding: 0;
  margin: 0 auto;
}

@media screen and (min-width: 2400px) {
  .doctorPage {
    height: 100vh;
    background-color: antiquewhite;
    margin: auto auto;
  }
}

.livecount {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  margin: 0;
  padding: 0;
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: livecount;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto auto;
      grid-template-columns: auto auto;
  -ms-grid-rows: auto;
      grid-template-rows: auto;
  font-weight: 900;
  text-align: center;
  place-items: center;
  width: 95%;
  margin: auto;
  gap: 15px;
  margin-top: 10%;
  margin-bottom: 10%;
}

.livecount .count_Item {
  display: -ms-grid;
  display: grid;
  place-items: center;
  width: 100px;
  height: 100px;
  -ms-grid-rows: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
  font-size: 15px;
  font-family: darkmode-on,sans-serif;
  font-weight: 900;
  font-style: normal;
  -webkit-box-shadow: 0px 0px 35px -9px rgba(0, 0, 0, 0.33);
          box-shadow: 0px 0px 35px -9px rgba(0, 0, 0, 0.33);
  background-color: #ffffff;
  border-radius: 20px;
  color: #359c8d;
}

.livecount .count_Item p {
  font-size: 8px;
}

.livecount .count_Item .img {
  place-items: center;
  margin: auto;
}

.livecount .count_Item .img img {
  width: 50px;
  border-radius: 50%;
  background-color: #FFF;
}

.livecount .count_Item a {
  background-color: bisque;
  color: #FFF;
}

.livecount .count_Item a:hover {
  color: #359c8d;
}

.livecount .count_Item:hover {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  background-color: #359c8d;
  color: #FFF;
}

.livecount .count_Item:hover a {
  color: #FFF;
}

@media (min-width: 468px) {
  .livecount {
    -ms-grid-columns: auto auto;
        grid-template-columns: auto auto;
    -ms-grid-rows: auto auto;
        grid-template-rows: auto auto;
  }
  .livecount .count_Item {
    width: 100px;
    height: 100px;
  }
  .livecount .count_Item p {
    font-size: 10px;
  }
  .livecount .count_Item .img img {
    width: 50px;
  }
}

@media (min-width: 768px) {
  .livecount {
    -ms-grid-columns: auto auto;
        grid-template-columns: auto auto;
    -ms-grid-rows: auto auto;
        grid-template-rows: auto auto;
  }
  .livecount .count_Item {
    width: 150px;
    height: 150px;
  }
  .livecount .count_Item p {
    font-size: 14px;
  }
  .livecount .count_Item .img img {
    width: 75px;
  }
}

.HomeFAQ {
  margin-top: 5%;
  margin-bottom: 5%;
}

.wrapper {
  width: 80%;
  margin: auto;
}

.wrapper .faqcontainer {
  background-color: #FFF;
  color: black;
  border-radius: 20px;
  -webkit-box-shadow: 0px 0px 35px -9px rgba(0, 0, 0, 0.33);
          box-shadow: 0px 0px 35px -9px rgba(0, 0, 0, 0.33);
  margin: 20px 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.wrapper .faqcontainer:hover {
  color: #FFF;
  background-color: #359c8d;
}

.wrapper .faqcontainer:hover .question:after {
  color: #FFF;
}

.wrapper h1 {
  text-align: center;
  margin-bottom: 20px;
}

.wrapper .question {
  font-size: 1.2rem;
  font-weight: 600;
  padding: 20px 80px 20px 20px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}

.wrapper .question::after {
  content: "\002B";
  font-size: 2.2rem;
  position: absolute;
  right: 20px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  color: #359c8d;
}

.wrapper .question.active::after {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.wrapper .answercont {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.wrapper .answer {
  padding: 0 20px 20px;
  line-height: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  -webkit-box-orient: vertical;
  visibility: visible;
}

@media screen and (max-width: 790px) {
  html {
    font-size: 14px;
  }
  .wrapper {
    width: 80%;
  }
}

.TreatmentQuicklinks {
  -ms-grid-row: 7;
  -ms-grid-column: 1;
  grid-area: TreatmentQuicklinks;
  height: 100%;
  width: 100%;
  border-radius: 15px;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: auto auto;
      grid-template-rows: auto auto;
      grid-template-areas: "title  ";
  gap: 25px;
}

.TreatmentQuicklinks .title {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: title;
  text-align: center;
}

.TreatmentQuicklinks .treatment {
  -webkit-box-shadow: 0px 0px 35px -9px rgba(0, 0, 0, 0.33);
          box-shadow: 0px 0px 35px -9px rgba(0, 0, 0, 0.33);
  border-radius: 15px;
  border: #359c8d solid 1.5px;
  text-align: center;
  font-family: 'darkmode-on';
  height: 100%;
  width: 100%;
  color: #359c8d;
  -webkit-transition: .5s;
  transition: .5s;
}

.TreatmentQuicklinks .treatment h1 {
  font-size: 15px;
}

.TreatmentQuicklinks .treatment img {
  background-color: white;
  border-radius: 50%;
}

.TreatmentQuicklinks .treatment.nocontent {
  border: none;
}

.TreatmentQuicklinks .treatment:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  background-color: #359c8d;
  color: white;
}

@media (min-width: 768px) {
  .TreatmentQuicklinks {
    -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas: "title  title title ";
  }
  .TreatmentQuicklinks .treatment h1 {
    font-size: 1.5rem;
  }
}

body {
  display: -ms-grid;
  display: grid;
}

body .career_wrapper {
  display: -ms-grid;
  display: grid;
  width: 80%;
  margin: auto;
  -ms-grid-columns: auto;
      grid-template-columns: auto;
  -ms-grid-rows: 1fr;
      grid-template-rows: 1fr;
}

body .Careersubmittion {
  max-width: 90%;
  margin: 0 auto;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -ms-grid-rows: auto;
      grid-template-rows: auto;
}

.Treatment_Wrapper {
  position: relative;
  min-height: 100vh;
  margin: auto;
  display: -ms-grid;
  display: grid;
  padding-bottom: 50px;
  padding-top: 125px;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -ms-grid-rows: .75fr  auto;
      grid-template-rows: .75fr  auto;
}

.Treatment_Wrapper .Cards_Wrapper {
  width: 100%;
  margin: auto;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  gap: 15px;
}

.Treatment_Wrapper .Cards_Wrapper .container {
  display: -ms-grid;
  display: grid;
  place-items: center;
}

.Treatment_Wrapper .Wrapper_Info {
  margin: auto;
  width: 90%;
  text-align: center;
}

@media (min-width: 600px) {
  .Treatment_Wrapper {
    -ms-grid-rows: .05fr  auto;
        grid-template-rows: .05fr  auto;
  }
  .Treatment_Wrapper .Cards_Wrapper {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1366px) {
  .Treatment_Wrapper {
    -ms-grid-rows: 75px  auto;
        grid-template-rows: 75px  auto;
  }
  .Treatment_Wrapper .Cards_Wrapper {
    -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 2500px) {
  .Treatment_Wrapper .Cards_Wrapper {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.voucher {
  padding-top: 100px;
  margin-bottom: 50px;
  width: 80%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto;
      grid-template-columns: auto;
  -ms-grid-rows: .75fr  auto;
      grid-template-rows: .75fr  auto;
      grid-template-areas: "voucherdetials" "invoiceitems";
  margin: auto;
}

.voucher .voucherdetials {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: voucherdetials;
  width: 80%;
  margin: auto;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: 1fr auto;
      grid-template-rows: 1fr auto;
      grid-template-areas: "description itemcount total" "voucheritems voucheritems voucheritems";
}

.voucher .voucherdetials .total {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
  grid-area: total;
  text-align: right;
}

.voucher .voucherdetials .total h1 {
  margin: auto;
}

.voucher .voucherdetials .itemcount {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  grid-area: itemcount;
  text-align: right;
}

.voucher .voucherdetials .voucheritems {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  grid-area: voucheritems;
  margin: auto;
}

.voucher .voucherdetials .description {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: description;
}

.voucher .invoiceitems {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  grid-area: invoiceitems;
  margin: auto;
}

@media (min-width: 600px) {
  .voucher {
    -ms-grid-rows: .05fr  auto;
        grid-template-rows: .05fr  auto;
  }
}

@media (min-width: 1366px) {
  .voucher {
    -ms-grid-rows: 1fr  auto;
        grid-template-rows: 1fr  auto;
  }
}

@media (min-width: 2500px) {
  .voucher {
    -ms-grid-rows: 75px  auto;
        grid-template-rows: 75px  auto;
  }
}

.voucherview {
  min-height: 100vh;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -ms-grid-rows: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-template-areas: "voucherdetails" "voucherItems";
}

.voucherview .voucherItems {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  grid-area: voucherItems;
}

.voucherview .voucherdetails {
  display: -ms-grid;
  display: grid;
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: voucherdetails;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  -ms-grid-rows: 1fr;
      grid-template-rows: 1fr;
      grid-template-areas: "details status" "voucherdescription voucherdescription";
}

.voucherview .voucherdetails .voucherdescription {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: voucherdescription;
  text-align: center;
}

.voucherview .voucherdetails .details {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: details;
  display: -ms-grid;
  display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1;
}

.voucherview .voucherdetails .status {
  display: -ms-grid;
  display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  grid-area: status;
}

.nav [type="checkbox"]:checked,
.nav [type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.nav .menu-icon:checked + label,
.nav .menu-icon:not(:checked) + label {
  position: fixed;
  top: 63px;
  right: 75px;
  display: block;
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 0;
  cursor: pointer;
  z-index: 10;
}

.nav .menu-icon:checked + label:before,
.nav .menu-icon:not(:checked) + label:before {
  position: absolute;
  content: '';
  display: block;
  width: 30px;
  height: 20px;
  z-index: 20;
  top: 0;
  left: 0;
  border-top: 2px solid #ececee;
  border-bottom: 2px solid #ececee;
  -webkit-transition: border-width 100ms 1500ms ease, top 100ms 1600ms cubic-bezier(0.23, 1, 0.32, 1), height 100ms 1600ms cubic-bezier(0.23, 1, 0.32, 1), background-color 200ms ease, -webkit-transform 200ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: border-width 100ms 1500ms ease, top 100ms 1600ms cubic-bezier(0.23, 1, 0.32, 1), height 100ms 1600ms cubic-bezier(0.23, 1, 0.32, 1), background-color 200ms ease, -webkit-transform 200ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: border-width 100ms 1500ms ease, top 100ms 1600ms cubic-bezier(0.23, 1, 0.32, 1), height 100ms 1600ms cubic-bezier(0.23, 1, 0.32, 1), background-color 200ms ease, transform 200ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: border-width 100ms 1500ms ease, top 100ms 1600ms cubic-bezier(0.23, 1, 0.32, 1), height 100ms 1600ms cubic-bezier(0.23, 1, 0.32, 1), background-color 200ms ease, transform 200ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 200ms cubic-bezier(0.23, 1, 0.32, 1);
}

.nav .menu-icon:checked + label:after,
.nav .menu-icon:not(:checked) + label:after {
  position: absolute;
  content: '';
  display: block;
  width: 22px;
  height: 2px;
  z-index: 20;
  top: 10px;
  right: 4px;
  background-color: #ececee;
  margin-top: -1px;
  -webkit-transition: width 100ms 1750ms ease, right 100ms 1750ms ease, margin-top 100ms ease, -webkit-transform 200ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: width 100ms 1750ms ease, right 100ms 1750ms ease, margin-top 100ms ease, -webkit-transform 200ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: width 100ms 1750ms ease, right 100ms 1750ms ease, margin-top 100ms ease, transform 200ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: width 100ms 1750ms ease, right 100ms 1750ms ease, margin-top 100ms ease, transform 200ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 200ms cubic-bezier(0.23, 1, 0.32, 1);
}

.nav .menu-icon:checked + label:before {
  top: 10px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  height: 2px;
  background-color: #ececee;
  border-width: 0;
  -webkit-transition: border-width 100ms 340ms ease, top 100ms 300ms cubic-bezier(0.23, 1, 0.32, 1), height 100ms 300ms cubic-bezier(0.23, 1, 0.32, 1), background-color 200ms 500ms ease, -webkit-transform 200ms 1700ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: border-width 100ms 340ms ease, top 100ms 300ms cubic-bezier(0.23, 1, 0.32, 1), height 100ms 300ms cubic-bezier(0.23, 1, 0.32, 1), background-color 200ms 500ms ease, -webkit-transform 200ms 1700ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: border-width 100ms 340ms ease, top 100ms 300ms cubic-bezier(0.23, 1, 0.32, 1), height 100ms 300ms cubic-bezier(0.23, 1, 0.32, 1), background-color 200ms 500ms ease, transform 200ms 1700ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: border-width 100ms 340ms ease, top 100ms 300ms cubic-bezier(0.23, 1, 0.32, 1), height 100ms 300ms cubic-bezier(0.23, 1, 0.32, 1), background-color 200ms 500ms ease, transform 200ms 1700ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 200ms 1700ms cubic-bezier(0.23, 1, 0.32, 1);
}

.nav .menu-icon:checked + label:after {
  width: 30px;
  margin-top: 0;
  right: 0;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  -webkit-transition: width 100ms ease, right 100ms ease, margin-top 100ms 500ms ease, -webkit-transform 200ms 1700ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: width 100ms ease, right 100ms ease, margin-top 100ms 500ms ease, -webkit-transform 200ms 1700ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: width 100ms ease, right 100ms ease, margin-top 100ms 500ms ease, transform 200ms 1700ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: width 100ms ease, right 100ms ease, margin-top 100ms 500ms ease, transform 200ms 1700ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 200ms 1700ms cubic-bezier(0.23, 1, 0.32, 1);
}

.nav .nav {
  position: fixed;
  top: 33px;
  right: 50px;
  display: block;
  width: 80px;
  height: 80px;
  padding: 0;
  margin: 0;
  z-index: 9;
  overflow: hidden;
  -webkit-box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.3);
  background-color: #66be9d;
  -webkit-animation: border-transform 7s linear infinite;
          animation: border-transform 7s linear infinite;
  -webkit-transition: top 350ms 1100ms cubic-bezier(0.23, 1, 0.32, 1), right 350ms 1100ms cubic-bezier(0.23, 1, 0.32, 1), width 650ms 400ms cubic-bezier(0.23, 1, 0.32, 1), height 650ms 400ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 250ms 1100ms ease;
  transition: top 350ms 1100ms cubic-bezier(0.23, 1, 0.32, 1), right 350ms 1100ms cubic-bezier(0.23, 1, 0.32, 1), width 650ms 400ms cubic-bezier(0.23, 1, 0.32, 1), height 650ms 400ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 250ms 1100ms ease;
  transition: top 350ms 1100ms cubic-bezier(0.23, 1, 0.32, 1), right 350ms 1100ms cubic-bezier(0.23, 1, 0.32, 1), transform 250ms 1100ms ease, width 650ms 400ms cubic-bezier(0.23, 1, 0.32, 1), height 650ms 400ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: top 350ms 1100ms cubic-bezier(0.23, 1, 0.32, 1), right 350ms 1100ms cubic-bezier(0.23, 1, 0.32, 1), transform 250ms 1100ms ease, width 650ms 400ms cubic-bezier(0.23, 1, 0.32, 1), height 650ms 400ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 250ms 1100ms ease;
}

@-webkit-keyframes border-transform {
  0%, 100% {
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}

@keyframes border-transform {
  0%, 100% {
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}

.nav .menu-icon:checked ~ .nav {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
  top: 50%;
  right: 50%;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
  width: 200%;
  height: 200%;
  -webkit-transition: top 350ms 700ms cubic-bezier(0.23, 1, 0.32, 1), right 350ms 700ms cubic-bezier(0.23, 1, 0.32, 1), width 750ms 1000ms cubic-bezier(0.23, 1, 0.32, 1), height 750ms 1000ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 250ms 700ms ease;
  transition: top 350ms 700ms cubic-bezier(0.23, 1, 0.32, 1), right 350ms 700ms cubic-bezier(0.23, 1, 0.32, 1), width 750ms 1000ms cubic-bezier(0.23, 1, 0.32, 1), height 750ms 1000ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 250ms 700ms ease;
  transition: top 350ms 700ms cubic-bezier(0.23, 1, 0.32, 1), right 350ms 700ms cubic-bezier(0.23, 1, 0.32, 1), transform 250ms 700ms ease, width 750ms 1000ms cubic-bezier(0.23, 1, 0.32, 1), height 750ms 1000ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: top 350ms 700ms cubic-bezier(0.23, 1, 0.32, 1), right 350ms 700ms cubic-bezier(0.23, 1, 0.32, 1), transform 250ms 700ms ease, width 750ms 1000ms cubic-bezier(0.23, 1, 0.32, 1), height 750ms 1000ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 250ms 700ms ease;
}

.nav .nav ul {
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  z-index: 6;
  text-align: center;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  list-style: none;
}

.nav .nav ul li {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  margin: 10px 0;
  text-align: center;
  list-style: none;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
  -webkit-transition: all 250ms linear;
  transition: all 250ms linear;
}

.nav .nav ul li:nth-child(1) {
  -webkit-transition-delay: 200ms;
          transition-delay: 200ms;
}

.nav .nav ul li:nth-child(2) {
  -webkit-transition-delay: 150ms;
          transition-delay: 150ms;
}

.nav .nav ul li:nth-child(3) {
  -webkit-transition-delay: 100ms;
          transition-delay: 100ms;
}

.nav .nav ul li:nth-child(4) {
  -webkit-transition-delay: 50ms;
          transition-delay: 50ms;
}

.nav .nav ul li:nth-child(5) {
  -webkit-transition-delay: 200ms;
          transition-delay: 200ms;
}

.nav .nav ul li:nth-child(6) {
  -webkit-transition-delay: 150ms;
          transition-delay: 150ms;
}

.nav .nav ul li:nth-child(7) {
  -webkit-transition-delay: 100ms;
          transition-delay: 100ms;
}

.nav .nav ul li:nth-child(8) {
  -webkit-transition-delay: 50ms;
          transition-delay: 50ms;
}

.nav .nav ul li a {
  font-family: 'Montserrat', sans-serif;
  font-size: 9vh;
  text-transform: uppercase;
  line-height: 1.2;
  font-weight: 800;
  display: inline-block;
  position: relative;
  color: #ececee;
  -webkit-transition: all 250ms linear;
  transition: all 250ms linear;
}

.nav .nav ul li a:hover {
  text-decoration: none;
  color: #000000;
}

.nav .nav ul li a:after {
  display: block;
  position: absolute;
  top: 50%;
  content: '';
  height: 2vh;
  margin-top: -1vh;
  width: 0;
  left: 0;
  background-color: #FFF;
  opacity: 0.8;
  -webkit-transition: width 250ms linear;
  transition: width 250ms linear;
}

.nav .nav ul li a:hover:after {
  width: 100%;
}

.nav .menu-icon:checked ~ .nav ul li {
  pointer-events: auto;
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition: opacity 350ms ease, -webkit-transform 250ms ease;
  transition: opacity 350ms ease, -webkit-transform 250ms ease;
  transition: opacity 350ms ease, transform 250ms ease;
  transition: opacity 350ms ease, transform 250ms ease, -webkit-transform 250ms ease;
}

.nav .menu-icon:checked ~ .nav ul li:nth-child(1) {
  -webkit-transition-delay: 1400ms;
          transition-delay: 1400ms;
}

.nav .menu-icon:checked ~ .nav ul li:nth-child(2) {
  -webkit-transition-delay: 1480ms;
          transition-delay: 1480ms;
}

.nav .menu-icon:checked ~ .nav ul li:nth-child(3) {
  -webkit-transition-delay: 1560ms;
          transition-delay: 1560ms;
}

.nav .menu-icon:checked ~ .nav ul li:nth-child(4) {
  -webkit-transition-delay: 1640ms;
          transition-delay: 1640ms;
}

.nav .menu-icon:checked ~ .nav ul li:nth-child(5) {
  -webkit-transition-delay: 1400ms;
          transition-delay: 1400ms;
}

.nav .menu-icon:checked ~ .nav ul li:nth-child(6) {
  -webkit-transition-delay: 1480ms;
          transition-delay: 1480ms;
}

.nav .menu-icon:checked ~ .nav ul li:nth-child(7) {
  -webkit-transition-delay: 1560ms;
          transition-delay: 1560ms;
}

.nav .menu-icon:checked ~ .nav ul li:nth-child(8) {
  -webkit-transition-delay: 1640ms;
          transition-delay: 1640ms;
}

.nav .logo {
  position: fixed;
  top: 60px;
  left: 50px;
  display: block;
  z-index: 11;
  -webkit-transition: all 250ms linear;
  transition: all 250ms linear;
}

.nav .logo img {
  height: 50px;
  width: auto;
  display: block;
}

@media (min-width: 1366px) {
  .nav .logo img {
    height: 75px;
    width: auto;
  }
}

@media screen and (max-width: 991px) {
  .nav .menu-icon:checked + label,
  .nav .menu-icon:not(:checked) + label {
    right: 55px;
  }
  .nav .logo {
    left: 30px;
  }
  .nav .nav {
    right: 30px;
  }
  .nav h1 {
    font-size: 25px;
    -webkit-text-stroke: 2px transparent;
    text-stroke: 2px transparent;
    -webkit-text-fill-color: #ffeba7;
    text-fill-color: #ffeba7;
    color: #ffeba7;
  }
  .nav .nav ul li a {
    font-size: 25px;
  }
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 50%;
  border-radius: 15px 15px 0 0;
  margin: auto;
}

table th {
  text-align: center;
  padding: 8px;
  color: #359c8d;
}

table td {
  text-align: center;
  border-bottom: 2px solid #359c8d;
}

table tr:hover {
  background-color: #359c8d;
  color: white;
}

table tr:nth-child(even) {
  background-color: #cfcfcf;
}

table tr:nth-child(even):hover {
  background-color: #359c8d;
  color: white;
}

table th {
  background: #FFF;
}

.voucherviewwrapper .voucher table {
  width: 100%;
}

.voucherviewwrapper .Paymentvoucherprint table {
  width: 100%;
}

.voucherviewwrapper .Paymentvoucherprint table tr:nth-child(even) {
  background-color: white;
}

.voucherviewwrapper .Paymentvoucherprint table tr:nth-child(even):hover {
  background-color: #359c8d;
  color: white;
}

select,
input {
  -webkit-appearance: none;
  font-weight: 750;
  color: black;
  font-family: 'darkmode-on';
  border: none;
  border-bottom: 3px solid #3db19f;
  background: transparent;
  font-size: 20px;
  float: left;
  margin: 1rem 0;
  padding: .25rem .5rem;
}

select[type=checkbox],
input[type=checkbox] {
  position: relative;
  width: 50px;
  height: 24px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: black;
  border-radius: 20px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
  cursor: pointer;
  border: none;
  margin: 0;
}

select[type=checkbox]:hover,
input[type=checkbox]:hover {
  border: none;
}

select[type=checkbox]:checked,
input[type=checkbox]:checked {
  background: #359c8d;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

select[type=checkbox]:checked::before,
input[type=checkbox]:checked::before {
  left: 25px;
  background: #ffffff;
}

select[type=checkbox]::before,
input[type=checkbox]::before {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 20px;
  top: 0;
  left: 0;
  background: white;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}

select::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  text-transform: capitalize;
  opacity: .25;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: black;
}

select:-ms-input-placeholder,
input:-ms-input-placeholder {
  text-transform: capitalize;
  opacity: .25;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: black;
}

select::-ms-input-placeholder,
input::-ms-input-placeholder {
  text-transform: capitalize;
  opacity: .25;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: black;
}

select::placeholder,
input::placeholder {
  text-transform: capitalize;
  opacity: .25;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: black;
}

select:focus::-webkit-input-placeholder,
input:focus::-webkit-input-placeholder {
  opacity: 0;
}

select:focus:-ms-input-placeholder,
input:focus:-ms-input-placeholder {
  opacity: 0;
}

select:focus::-ms-input-placeholder,
input:focus::-ms-input-placeholder {
  opacity: 0;
}

select:focus::placeholder,
input:focus::placeholder {
  opacity: 0;
}

select:focus,
input:focus {
  outline: none;
  border-bottom: 1px solid black;
}

select:hover,
input:hover {
  border-bottom: 3px solid #3db19f;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

select:hover::-webkit-input-placeholder,
input:hover::-webkit-input-placeholder {
  opacity: 1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: #359c8d;
}

select:hover:-ms-input-placeholder,
input:hover:-ms-input-placeholder {
  opacity: 1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: #359c8d;
}

select:hover::-ms-input-placeholder,
input:hover::-ms-input-placeholder {
  opacity: 1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: #359c8d;
}

select:hover::placeholder,
input:hover::placeholder {
  opacity: 1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: #359c8d;
}

select[type=submit],
input[type=submit] {
  cursor: pointer;
}

select {
  float: none;
}

.A_Tag_as_Button {
  border: none;
  border-radius: 30px;
  padding: .25rem 1rem;
  text-align: center;
  color: black;
  font-size: 16px;
  margin: 1rem 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-family: darkmode-on,sans-serif;
  font-weight: 800;
  font-style: normal;
  color: white;
  background-color: #359c8d;
  -webkit-box-shadow: 10px 10px 29px -9px rgba(0, 0, 0, 0.33);
          box-shadow: 10px 10px 29px -9px rgba(0, 0, 0, 0.33);
  border: none;
  border-radius: 50px;
  font-size: 20px;
}

.A_Tag_as_Button:hover {
  cursor: pointer;
  color: #359c8d;
  background-color: #ffffff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.A_Tag_as_Button:focus {
  outline: none;
}

.A_Tag_as_Button.disabled, .A_Tag_as_Button:disabled {
  background-color: gray;
  color: white;
}

.A_Tag_as_Button:disabled:hover {
  border-bottom: none;
}

.A_Tag_as_Button.disabled:hover {
  border-bottom: none;
}

button {
  border: none;
  border-radius: 30px;
  padding: .25rem 1rem;
  text-align: center;
  color: black;
  font-size: 16px;
  -webkit-box-shadow: 4px 6px 6px 0px rgba(0, 0, 0, 0.16);
          box-shadow: 4px 6px 6px 0px rgba(0, 0, 0, 0.16);
  margin: 1rem 0;
  background-color: #359c8d;
  font-family: darkmode-on,sans-serif;
  font-weight: 800;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-family: darkmode-on,sans-serif;
  font-weight: 800;
  font-style: normal;
  color: white;
  background-color: #359c8d;
  -webkit-box-shadow: 10px 10px 29px -9px rgba(0, 0, 0, 0.33);
          box-shadow: 10px 10px 29px -9px rgba(0, 0, 0, 0.33);
  border: none;
  border-radius: 50px;
  height: 35px;
  font-size: 20px;
}

button:hover {
  cursor: pointer;
  color: #359c8d;
  background-color: #ffffff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

button:focus {
  outline: none;
}

button.disabled, button:disabled {
  background-color: gray;
  color: white;
}

button:disabled:hover {
  border-bottom: none;
}

button.disabled:hover {
  border-bottom: none;
}

.Callback_request {
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.Callback_request form {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: auto auto auto auto;
      grid-template-rows: auto auto auto auto;
  width: 80%;
  margin: auto;
  margin-top: 10%;
  margin-bottom: 10%;
}

.Callback_request form h3 {
  font-family: darkmode-on,sans-serif;
  font-weight: 900;
}

@media (min-width: 600px) {
  .Callback_request form {
    width: 60%;
  }
}

@media (min-width: 1366px) {
  .Callback_request form {
    width: 50%;
  }
}

@media (min-width: 2500px) {
  .Callback_request form {
    width: 550px;
  }
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  -webkit-box-shadow: 2px 2px 3px #999;
          box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.my-float {
  margin-top: 16px;
}

* {
  font-family: 'Poppins', sans-serif;
}

.contentBx {
  font-family: 'darkmode-on';
}

/*
body{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #131313;
}*/
.container {
  position: relative;
}

.Short_Description p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  -webkit-box-orient: vertical;
  visibility: visible;
}

.container .card {
  -webkit-box-shadow: 0px 0px 35px -9px rgba(0, 0, 0, 0.33);
          box-shadow: 0px 0px 35px -9px rgba(0, 0, 0, 0.33);
  border: #359c8d solid 2px;
  position: relative;
  width: 100%;
  max-width: 350px;
  height: 450px;
  background: #FFF;
  border-radius: 20px;
  overflow: hidden;
}

.container .card:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #359c8d;
  -webkit-clip-path: circle(150px at 80% 20%);
          clip-path: circle(150px at 80% 20%);
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.container .card:hover:before {
  -webkit-clip-path: circle(300px at 80% -20%);
          clip-path: circle(300px at 80% -20%);
}

.container .card:after {
  content: 'Dental';
  position: absolute;
  top: 30%;
  left: -20%;
  font-size: 7em;
  font-weight: 800;
  font-style: italic;
  color: rgba(113, 255, 220, 0.589);
}

.container .card .imgBx {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 5;
  width: 100%;
  height: 220px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.container .card:hover .imgBx {
  top: 0%;
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
}

.container .card .imgBx img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100px;
}

.container .card .contentBx {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  text-align: center;
  -webkit-transition: 1s;
  transition: 1s;
  z-index: 4;
}

.container .card:hover .contentBx {
  height: 210px;
}

.container .card .contentBx h2 {
  position: relative;
  font-weight: 600;
  letter-spacing: 1px;
  color: #359c8d;
  margin: 0;
}

.container .card .contentBx .Short_Description {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 8px 20px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
  padding-top: 0;
  padding-bottom: 0;
}

.container .card:hover .contentBx .Short_Description {
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 0.5s;
          transition-delay: 0.5s;
}

.container .card:hover .contentBx .color {
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 0.6s;
          transition-delay: 0.6s;
}

.container .card .contentBx .Short_Description p {
  color: #359c8d;
}

.container .card .contentBx .Short_Description h3 {
  color: #359c8d;
  font-weight: 300;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-right: 10px;
}

.container .card .contentBx .Short_Description span {
  width: 26px;
  height: 26px;
  text-align: center;
  line-height: 26px;
  font-size: 14px;
  display: inline-block;
  background: #fff;
  margin: 0 5px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: black;
  border-radius: 4px;
  cursor: pointer;
}

.container .card .contentBx a {
  display: inline-block;
  padding: 10px 20px;
  background: #359c8d;
  border-radius: 4px;
  margin-top: 10px;
  text-decoration: none;
  font-weight: 600;
  color: #FFF;
  opacity: 0;
  -webkit-transform: translateY(50px);
          transform: translateY(50px);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  margin-top: 0;
}

.container .card:hover .contentBx a {
  opacity: 1;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-transition-delay: 0.75s;
          transition-delay: 0.75s;
}

/* .flip-card-container */
.flip-card-container {
  width: 100%;
  margin: auto;
  max-width: 310px;
  height: 350px;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}

/* .flip-card */
.flip-card {
  width: inherit;
  height: inherit;
  position: relative;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transition: .6s .1s;
  transition: .6s .1s;
}

/* hover and focus-within states */
.flip-card-container:hover .flip-card,
.flip-card-container:focus-within .flip-card {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

/* .card-... */
.card-front,
.card-back {
  -webkit-box-shadow: 10px 10px 29px -9px rgba(0, 0, 0, 0.33);
          box-shadow: 10px 10px 29px -9px rgba(0, 0, 0, 0.33);
  width: 100%;
  height: 100%;
  border-radius: 24px;
  background: #66be9d;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

/* .card-front */
.card-front {
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
  z-index: 2;
  /* figcaption */
}

.card-front p {
  width: 80%;
  margin: auto;
  margin-top: 40%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  /* number of lines to show */
  -webkit-box-orient: vertical;
  visibility: visible;
}

.card-front figcaption {
  width: auto;
  margin-top: 15%;
  padding: 8px 22px;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: 2px;
  word-spacing: 6px;
  text-align: right;
  position: absolute;
  top: 0;
  right: 0;
  color: #000000;
  background: white;
  -webkit-box-shadow: 10px 10px 29px -9px rgba(0, 0, 0, 0.33);
          box-shadow: 10px 10px 29px -9px rgba(0, 0, 0, 0.33);
  border-radius: 15px 0 0 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

/* .card-back */
.card-back {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  z-index: 1;
  margin: 0;
  display: -ms-grid;
  display: grid;
}

.card-back button {
  padding: 10px;
}

/* figure */
figure {
  z-index: -1;
}

/* figure, .img-bg */
figure,
.img-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* img */
}

figure img,
.img-bg img {
  height: 100%;
  border-radius: 24px;
}

/* hover state */
.flip-card-container:hover .card-front .img-bg::before {
  width: 6px;
}

@media (min-width: 600px) {
  .cards {
    -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto;
        grid-template-rows: auto;
    gap: 2.5%;
    place-items: center;
  }
}

@media (min-width: 1366px) {
  .cards {
    -ms-grid-columns: 1fr 1fr  1fr 1fr;
        grid-template-columns: 1fr 1fr  1fr 1fr;
    -ms-grid-rows: auto;
        grid-template-rows: auto;
    gap: 2.5%;
    place-items: center;
  }
}

@media (min-width: 2500px) {
  .card {
    -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
  }
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #346150;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #346150;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb {
  background-color: #66be9d;
  border-radius: 50px;
  background-image: -webkit-gradient(linear, 0 0, 0 100%, color-stop(0.5, rgba(255, 255, 255, 0.2)), color-stop(0.5, transparent), to(transparent));
}

:root {
  --footer-bg: #359c8d;
  --color: #000000;
  --color-smooth: #161616;
  --input-border: #000000;
  --btn-bg-color: #f1f2fc;
  --btn-hover-bg: #d5d9f6;
  --btn-color: #000000;
}

footer {
  font-size: 16px;
  -webkit-box-shadow: 0px 0px 35px -9px rgba(0, 0, 0, 0.33);
          box-shadow: 0px 0px 35px -9px rgba(0, 0, 0, 0.33);
  border-top: 3px solid #359c8d;
  padding: 2em 4em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 1em;
}

footer ul {
  list-style: none;
}

.col {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.col-title {
  color: var(--color);
  font-size: 1.1em;
  font-weight: 500;
  padding: 0.5em 0;
}

.col-list a {
  color: var(--color-smooth);
  text-decoration: none;
  line-height: 1.4;
  font-weight: 400;
}

.col-list a:hover,
.col-list a:focus {
  text-decoration: underline;
}

.subscribe-form,
.social-media {
  display: -ms-grid;
  display: grid;
  max-width: 150px;
  gap: 0.5em;
  padding: 0.5em 0;
}

.subscribe-input {
  /* width: auto;
    flex: 1;
    background: transparent;
    border: 0.06em solid var(--input-border);
    font-size: 1em;
    font-weight: 500;
    padding: 0.5em;
    border-radius: 0.3em;
    transition: all 0.25s ease 0s;
    display: inline-block;
    color: var(--color-smooth);*/
}

.subscribe-input:focus {
  outline: none;
  border-color: var(--color);
  color: var(--color);
}

.subscribe-btn {
  /*  background: var(--btn-bg-color);
    border: none;
    font-size: 1em;
    font-weight: 600;
    padding: 0.5em;
    border-radius: 0.3em;
    transition: all 0.25s ease 0s;
    display: inline-block;
    color: var(--btn-color);
    cursor: pointer;*/
}

.form-title,
.social-col-title {
  font-size: 1em;
  color: var(--color);
  font-weight: 500;
}

.social-media {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.social-media-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.5rem;
}

.social-media-list a {
  padding: 0.5em;
  color: var(--color-smooth);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 0.3em;
  -webkit-transition: all 0.25s ease 0s;
  transition: all 0.25s ease 0s;
}

.social-media-list a:hover,
.social-media-list a:focus {
  color: var(--color);
  background: #ffffff1a;
}

.social-media-list svg {
  width: 1.5em;
  height: 1.5em;
  -webkit-transition: all 0.25s ease 0s;
  transition: all 0.25s ease 0s;
}

@media screen and (max-width: 750px) {
  footer {
    padding: 2em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.doctor {
  width: 80%;
  margin: 0 auto;
  display: -ms-grid;
  display: grid;
  padding-top: 150px;
  padding-bottom: 150px;
}

.doctor .card {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
      grid-template-areas: "docimg" "doccontent" "Doc_Extras";
  -webkit-transition: 0.5s;
  transition: 0.5s;
  display: grid;
  -webkit-box-shadow: 0px 0px 35px -9px rgba(0, 0, 0, 0.33);
          box-shadow: 0px 0px 35px -9px rgba(0, 0, 0, 0.33);
  border-radius: 30px;
  width: 100%;
  text-align: center;
  background-color: #f1f1f1;
  padding: 0;
}

.doctor .card:hover {
  background-color: #359c8d;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.doctor .card:hover .docimg img {
  -webkit-box-shadow: 0px 0px 35px -9px rgba(0, 0, 0, 0.33);
          box-shadow: 0px 0px 35px -9px rgba(0, 0, 0, 0.33);
}

.doctor .card:hover .Doc_Extras {
  color: white;
}

.doctor .card:hover .doccontent {
  color: white;
}

.doctor .card:hover .doccontent .outline {
  -webkit-text-stroke: 1px white;
  color: #359c8d;
}

.doctor .card:hover h2 {
  color: white;
}

.doctor .card:hover h1 {
  color: white;
}

.doctor .card .docimg {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: docimg;
  place-self: center;
}

.doctor .card .docimg img {
  -webkit-box-shadow: 0px 0px 35px -9px rgba(0, 0, 0, 0.33);
          box-shadow: 0px 0px 35px -9px rgba(0, 0, 0, 0.33);
  border: 3px solid #f1f1f1;
  border-radius: 360px;
  margin: 50px auto;
  display: block;
  width: 150px;
}

.doctor .card .Doc_Extras {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  grid-area: Doc_Extras;
  color: black;
  width: 95%;
  margin: 0 auto;
  text-align: left;
}

.doctor .card .doccontent {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  grid-area: doccontent;
  padding-bottom: 15px;
  width: 80%;
  margin: auto;
  text-align: left;
}

.doctor .card .doccontent h2 {
  margin: 0;
  font-family: darkmode-on;
  font-weight: 900;
  font-size: 15px;
}

.doctor .card .doccontent .outline {
  -webkit-text-stroke: 1px black;
  color: transparent;
}

.doctor .card .doccontent h1 {
  margin: 0;
  font-size: 12px;
  font-family: darkmode-on;
  font-weight: 900;
}

.doctor .card .doccontent a {
  margin: 0;
  font-family: darkmode-on;
  font-size: 12px;
  font-weight: 900;
  color: #004680;
  word-wrap: break-word;
}

@media (min-width: 600px) {
  .doctor .card {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    -ms-grid-rows: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas: "doccontent docimg" "Doc_Extras Doc_Extras";
  }
  .doctor .card .docimg img {
    width: 200px;
  }
  .doctor .card .doccontent h2 {
    font-size: 30px;
  }
  .doctor .card .doccontent .outline {
    -webkit-text-stroke: 2px black;
  }
  .doctor .card .doccontent h1 {
    font-size: 15px;
  }
  .doctor .card .doccontent a {
    font-size: 15px;
  }
}

@media (min-width: 1366px) {
  .doctor {
    -ms-grid-columns: auto auto;
        grid-template-columns: auto auto;
    -ms-grid-rows: auto;
        grid-template-rows: auto;
    place-items: center;
  }
}

@media (min-width: 2500px) {
  .doctorPagewrapper {
    height: 100Vh;
  }
  .doctor .card .Doc_Extras {
    margin: 0;
    font-family: darkmode-on;
    font-size: 15px;
    display: block;
    width: 80%;
    margin: auto;
    text-align: center;
  }
}

.aboutus {
  text-align: center;
  padding: 25px 5px;
  width: 80%;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: auto auto auto;
      grid-template-rows: auto auto auto;
  margin: 0 auto;
  background-color: white;
  -webkit-box-shadow: 0px 0px 35px -9px rgba(0, 0, 0, 0.33);
          box-shadow: 0px 0px 35px -9px rgba(0, 0, 0, 0.33);
  border-radius: 15px;
      grid-template-areas: "heading detail" "Branches_Main Branches_Main";
}

.aboutus .heading {
  text-align: center;
}

.aboutus .Branches_Main {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: Branches_Main;
}

@media (min-width: 768px) {
  .aboutus {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
  }
  .aboutus .heading {
    text-align: center;
    margin: auto 0;
  }
}

/* PRELOADER CSS */
.page-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  position: absolute;
  background: #66be9d;
  z-index: 1000;
  display: -ms-grid;
  display: grid;
}

.page-loader .txt {
  color: #666;
  text-align: center;
  top: 40%;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  font-weight: bold;
  line-height: 1.5;
}

.page-loader .spinner {
  display: -ms-grid;
  display: grid;
  width: 80%;
  margin: auto;
}

.page-loader .spinner img {
  width: 256px;
  margin: auto;
  place-items: center;
}

/* SPINNER ANIMATION */
/*.spinner {
	position: relative;
	top: 35%;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  background-color: #fff;

  border-radius: 100%;  
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}
*/
@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

.Paymentvoucherprint {
  margin: auto;
}

.Paymentvoucherprint .header {
  position: relative;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  max-width: 100%;
  height: auto;
}

.Paymentvoucherprint .header img {
  -o-object-fit: scale-down;
     object-fit: scale-down;
  width: 100%;
  height: 100%;
}

.Paymentvoucherprint .voucherdetails {
  display: -ms-grid;
  display: grid;
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: voucherdetails;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  -ms-grid-rows: 1fr;
      grid-template-rows: 1fr;
      grid-template-areas: "details status" "voucherdescription voucherdescription";
}

.Paymentvoucherprint .voucherdetails .voucherdescription {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: voucherdescription;
  text-align: center;
}

.Paymentvoucherprint .voucherdetails .details {
  margin: auto;
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: details;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  -ms-grid-rows: 1;
      grid-template-rows: 1;
}

.Paymentvoucherprint .voucherdetails .status {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  -ms-grid-rows: 1fr;
      grid-template-rows: 1fr;
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  grid-area: status;
}

.Paymentvoucherprint .footer {
  position: absolute;
  left: 0%;
  bottom: 0%;
  width: 100%;
  max-width: 100%;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.Paymentvoucherprint .footer img {
  -o-object-fit: scale-down;
     object-fit: scale-down;
  width: 100%;
  height: 100%;
}

.voucherviewwrapper {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -ms-grid-rows: auto auto auto;
      grid-template-rows: auto auto auto;
      grid-template-areas: "voucher" "voucher_submit" "Paymentvoucherprint";
}

.voucherviewwrapper .voucher {
  display: -ms-grid;
  display: grid;
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: voucher;
}

.voucherviewwrapper .voucher_submit {
  display: -ms-grid;
  display: grid;
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  grid-area: voucher_submit;
}

.voucherviewwrapper .Paymentvoucherprint {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  grid-area: Paymentvoucherprint;
}

/*.Summery_Wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    grid-template-rows: repeat(auto-fit, minmax(30%, 1fr));
    width: 80%;
    max-width: 100vw;
    margin: 150px auto;
    
    @media screen and (min-width: 1800px) {
    grid-template-columns: repeat(5, minmax(250px, 1fr));
    }
    }
    
    
    .Summery_Wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    width: 80%;
    max-width: 100vw;
    margin: 150px auto;
    }
    
    @media screen and (min-width: 1800px) {
    .Summery_Wrapper {
    grid-template-columns: repeat(3, minmax(250px, 1fr));
    grid-template-rows: repeat(auto-fit, minmax(250px, 1fr));
    }
    }*/
.Summery_Wrapper {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(250px, 1fr))[auto-fill];
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  -ms-grid-rows: (250px)[10];
      grid-template-rows: repeat(10, 250px);
  grid-gap: 20px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 80%;
  max-width: 100vw;
  margin: 150px auto;
}

.Blog_Summery {
  background-color: #0b5e5e;
  width: 95%;
  height: 50%;
  border-radius: 15px;
  margin: 5px auto;
  text-align: center;
  background-size: cover;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2), 0 5px 15px 0 rgba(0, 0, 0, 0.19);
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2), 0 5px 15px 0 rgba(0, 0, 0, 0.19);
}

.Blog_Summery p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 80%;
  color: white;
  font-size: 12px;
  margin: 0 auto;
  padding: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  font-weight: 600;
  letter-spacing: 1px;
  font-family: 'Roboto', sans-serif;
}

.Blog_Summery h1 {
  color: white;
  font-size: 1.5rem;
  margin: 0;
  padding: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: 'Roboto', sans-serif;
}

.Blog_Summery h2 {
  color: white;
}

.Blog_Summery a {
  color: white;
  font-size: 12px;
  margin: 0;
  padding: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: 'Roboto', sans-serif;
  text-decoration: underline;
}

.rtc_Blog_01 {
  width: 80%;
  margin: 150px auto;
}

.container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[6];
      grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: 100px 300px;
  grid-gap: 10px;
  grid-auto-flow: dense;
  padding-bottom: 50px;
  width: 80%;
  margin: auto;
  margin-top: 150px;
}

.gallery-item {
  width: 100%;
  height: 100%;
  position: relative;
}

.gallery-item .image {
  border-radius: 15px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.gallery-item .image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: 50% 50%;
     object-position: 50% 50%;
  cursor: pointer;
  -webkit-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
}

.gallery-item:hover .image img {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

.gallery-item .text {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: white;
  font-size: 25px;
  pointer-events: none;
  z-index: 4;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
  -webkit-backdrop-filter: blur(5px) saturate(1.8);
  backdrop-filter: blur(5px) saturate(1.8);
}

.gallery-item:hover .text {
  opacity: 1;
  -webkit-animation: move-down .3s linear;
          animation: move-down .3s linear;
  padding: 1em;
  width: 100%;
}

.w-1 {
  grid-column: span 1;
}

.w-2 {
  grid-column: span 2;
}

.w-3 {
  grid-column: span 3;
}

.w-4 {
  grid-column: span 4;
}

.w-5 {
  grid-column: span 5;
}

.w-6 {
  grid-column: span 6;
}

.h-1 {
  grid-row: span 1;
}

.h-2 {
  grid-row: span 2;
}

.h-3 {
  grid-row: span 3;
}

.h-4 {
  grid-row: span 4;
}

.h-5 {
  grid-row: span 5;
}

.h-6 {
  grid-row: span 6;
}

@media screen and (max-width: 500px) {
  .container {
    -ms-grid-columns: (1fr)[1];
        grid-template-columns: repeat(1, 1fr);
  }
  .w-1, .w-2, .w-3, .w-4, .w-5, .w-6 {
    grid-column: span 1;
  }
  .h-1, .h-2, .h-3, .h-4, .h-5, .h-6 {
    grid-row: span 3;
  }
}

@keyframes move-down {
  0% {
    top: 10%;
  }
  50% {
    top: 35%;
  }
  100% {
    top: 50%;
  }
}

body {
  min-height: 100vh;
}

body .maincontent {
  position: absolute;
  padding-top: 470px;
  padding-bottom: 100px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  color: white;
  margin-bottom: 150px;
}

body .maincontent p {
  width: 100%;
}

body .bleachingtreatment {
  padding-bottom: 100px;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  margin-top: 0;
  width: 90%;
}

body .bleachingtreatment .blcwrapper {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto;
      grid-template-columns: auto;
  -ms-grid-rows: auto;
      grid-template-rows: auto;
  grid-row-gap: 20px;
}

body .bleachingtreatment .blcwrapper .card {
  -webkit-box-shadow: 0px 0px 35px -9px rgba(0, 0, 0, 0.33);
          box-shadow: 0px 0px 35px -9px rgba(0, 0, 0, 0.33);
  padding: 50px;
  text-align: center;
  border-radius: 15px;
  background-color: aliceblue;
  color: #359c8d;
}

@media (min-width: 600px) {
  body .maincontent {
    color: white;
  }
  body .maincontent p {
    width: 60%;
  }
}

body {
  margin: 0;
}

.card {
  -webkit-box-shadow: 0px 0px 35px -9px rgba(0, 0, 0, 0.33);
          box-shadow: 0px 0px 35px -9px rgba(0, 0, 0, 0.33);
  padding: 50px;
  text-align: center;
  border-radius: 15px;
  background-color: aliceblue;
  color: #359c8d;
}

.header {
  position: relative;
  text-align: center;
  background-size: cover;
  background-position: center;
  color: white;
}

.header .inner-header {
  height: 65vh;
  width: 100%;
  margin: 0;
  padding: 0;
}

.header .flex {
  /*Flexbox for containers*/
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
}

.header .waves {
  position: relative;
  width: 100%;
  height: 15vh;
  margin-bottom: -7px;
  /*Fix for safari gap*/
  min-height: 100px;
  max-height: 150px;
}

/* Animation */
.parallax > use {
  -webkit-animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
          animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.parallax > use:nth-child(1) {
  -webkit-animation-delay: -2s;
          animation-delay: -2s;
  -webkit-animation-duration: 7s;
          animation-duration: 7s;
}

.parallax > use:nth-child(2) {
  -webkit-animation-delay: -3s;
          animation-delay: -3s;
  -webkit-animation-duration: 10s;
          animation-duration: 10s;
}

.parallax > use:nth-child(3) {
  -webkit-animation-delay: -4s;
          animation-delay: -4s;
  -webkit-animation-duration: 13s;
          animation-duration: 13s;
}

.parallax > use:nth-child(4) {
  -webkit-animation-delay: -5s;
          animation-delay: -5s;
  -webkit-animation-duration: 20s;
          animation-duration: 20s;
}

@-webkit-keyframes move-forever {
  0% {
    -webkit-transform: translate3d(-90px, 0, 0);
            transform: translate3d(-90px, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(85px, 0, 0);
            transform: translate3d(85px, 0, 0);
  }
}

@keyframes move-forever {
  0% {
    -webkit-transform: translate3d(-90px, 0, 0);
            transform: translate3d(-90px, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(85px, 0, 0);
            transform: translate3d(85px, 0, 0);
  }
}

.Contentwrapper {
  padding-bottom: 100px;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  margin-top: 50px;
  width: 90%;
}

.cardwrapper {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto;
      grid-template-columns: auto;
  -ms-grid-rows: auto;
      grid-template-rows: auto;
  grid-row-gap: 20px;
  grid-row-gap: 20px;
}

.cardsplitcard {
  display: -ms-grid;
  display: grid;
  -webkit-box-shadow: 0px 0px 35px -9px rgba(0, 0, 0, 0.33);
          box-shadow: 0px 0px 35px -9px rgba(0, 0, 0, 0.33);
  -ms-grid-rows: auto;
      grid-template-rows: auto;
  -ms-grid-columns: 1fr  1fr;
      grid-template-columns: 1fr  1fr;
  padding: 50px;
  text-align: left;
  border-radius: 15px;
  background-color: aliceblue;
  color: #359c8d;
}

/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }
  .content {
    height: 30vh;
  }
  h1 {
    font-size: 24px;
  }
  .cardsplitcard {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: auto;
        grid-template-columns: auto;
    -ms-grid-rows: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
  }
}

@media (min-width: 2000px) {
  .header {
    width: 100vw;
    -o-object-fit: cover;
       object-fit: cover;
    z-index: -1;
  }
}

.contentone {
  width: 90%;
}

.content_two {
  width: 90%;
}
