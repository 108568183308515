

/* PRELOADER CSS */
.page-loader{
    
  position: fixed;
	width: 100%;
	height:100%;
	position: absolute;
//	background: #ffffff;
	background: #66be9d;
	z-index: 1000;
	.txt{
		color: #666;
		text-align: center;
		top: 40%;
		position: relative;
		text-transform: uppercase;
		letter-spacing: 0.3rem;
		font-weight: bold;
		line-height: 1.5;
	}
display: grid;
    .spinner{
        display: grid;
        img{
            width: 256px;
            margin: auto;
        place-items: center;

        }
        width: 80%;
        margin: auto;
    }
}

/* SPINNER ANIMATION */
/*.spinner {
	position: relative;
	top: 35%;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  background-color: #fff;

  border-radius: 100%;  
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}
*/
@-webkit-keyframes sk-scaleout {
  0% { -webkit-transform: scale(0) }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}