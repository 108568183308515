::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
background-color: #346150;
	//	background-color:  #66be9d;

}

::-webkit-scrollbar
{
	width: 10px;
	background-color: #346150;
    border-radius: 50px;
}

::-webkit-scrollbar-thumb
{
	//	background-color: #346150;

background-color:  #66be9d;
    border-radius: 50px;
	background-image: -webkit-gradient(linear, 0 0, 0 100%,
	                   color-stop(.5, rgba(255, 255, 255, .2)),
					   color-stop(.5, transparent), to(transparent));
}
