.sociallinks{
    display: grid;
    grid-template-columns: 1fr ;
    grid-template-rows: 1fr 1fr;
    width: 80%;
    margin: auto;
    gap: 2.5%;
    .title{
        text-align: center;
        h4{
            margin: 15px auto;
            font-family: darkmode-on;
            font-weight: 900;
            font-size: 15px; 
        }
    }
    .links{
place-items: center;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    gap: 2.5%;
        
    }
     a{
         img{
             width: 25px;
         }
     }
     .review{

        text-align: center;
         h4{
            margin: 15px auto;
            font-family: darkmode-on;
            font-weight: 900;
            font-size: 15px; 
         }
      
     }

 }
 .hotline{
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 0px 35px -9px rgba(0,0,0,0.33);
    padding: 20px;
        width: 50%;
        text-align: center;
        margin: 0 auto;
    h1{
        text-decoration: underline;
    
    }
    a {
            color: var(--color-smooth);
            text-decoration: none;
            line-height: 1.4;
            font-weight: 600;
            font-size: 30px;
          }
          
           a:hover,
           a:focus {
            text-decoration: underline;
          }
    
    }
.Quicklinks{
    margin-bottom: 25px;
   // background-color: aquamarine;
    .doc_cards{
        display: grid;
        grid-template-rows:auto ;
        width: 80%;
    }
}
 @media (min-width: 600px) {
    .sociallinks{
        .links{
            a{
                img{
                    width: 50px;
                }
            }
        }
        .review{
            h4{
                font-size: 20px;

            }
           
        }
    }
   
 }

 @media (min-width: 1366px) {    .sociallinks{
    .review{
      
      
    }
    .links{
        a{
            img{
                width:75px;
            }
        }
    }
        }
       
     }