body{
   // background-color: aqua;
   .maincontent{
    position: absolute;
    padding-top: 470px;
    padding-bottom: 100px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    color: rgb(255, 255, 255);
    //            background-color: beige;
                p{
                    width: 100%;
                }
                margin-bottom: 150px;
   }

   min-height: 100vh;
    .bleachingtreatment{
        padding-bottom: 100px;
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        
        margin: auto;
        margin-top: 0;

        width: 90%;
        .blcwrapper{
            display: grid;
            grid-template-columns: auto;
            grid-template-rows:auto ;
        //    background-color: aquamarine;
            grid-row-gap: 20px;
          
            .card{
                box-shadow: 0px 0px 35px -9px rgba(0,0,0,0.33);

                padding: 50px;
                text-align: center;
                border-radius: 15px;
                background-color: aliceblue;
                color: #359c8d;
            }
        }
    }
}

@media (min-width: 600px) {
    body{
        .maincontent{
            color: rgb(255, 255, 255);
                
                                 p{
                                     width: 60%;
                                 }
                             }
        .bleachingtreatment{
             .blcwrapper{
                 
              
         }
     }}
}