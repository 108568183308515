.Blog_Summery{
    background-color: rgb(11, 94, 94);
    //max-width: 30%;
    width: 95%;
    height: 50%;
    border-radius: 15px;
    margin:5px auto;
    text-align: center;
    background-size: cover;
   
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2), 0 5px 15px 0 rgba(0, 0, 0, 0.19);
       
      p{

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    
        width: 80%;
        color: white;
        font-size: 12px;
        margin: 0 auto;
        padding: 0;
        padding-top: 10px;
        padding-bottom: 10px;
        text-align: center;
        font-weight: 600;
        //text-transform: uppercase;
        letter-spacing: 1px;
        font-family: 'Roboto', sans-serif;

        //hide

       // display:none;
     }

    
         h1{
            color: white;
            font-size: 1.5rem;
            margin: 0;
            padding: 0;
            padding-top: 10px;
            padding-bottom: 10px;
            text-align: center;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-family: 'Roboto', sans-serif;

         }
         h2{
            color: white;
         }
         a{
            color: white;
            font-size: 12px;
            margin: 0;
            padding: 0;
            padding-top: 10px;
            padding-bottom: 10px;
            text-align: center;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-family: 'Roboto', sans-serif;
            text-decoration: underline;
         }
}