
.Treatment_Wrapper{
    position: relative;
    min-height: 100vh;
 
    margin: auto;
    display: grid;
 //   margin-top: 100px;
  //margin-bottom: 100px;
  padding-bottom: 50px;
   padding-top: 125px;
    grid-template-columns: 1fr;
//    grid-template-rows:  150px auto;
    grid-template-rows:  .75fr  auto;
    .Cards_Wrapper{

width: 100%;
        margin: auto;
        display: grid;
        grid-template-columns: 1fr;
        gap: 15px;
        // gap:2.5%;
        .container{
            display: grid;
            place-items: center;
            
        }
     
    }
    .Wrapper_Info{
        margin: auto;
        width: 90%;
        text-align: center;
    }
   
}

@media (min-width: 600px) {

    .Treatment_Wrapper{
        grid-template-rows:  .05fr  auto;

        .Cards_Wrapper{
        grid-template-columns: 1fr 1fr;
      
    }
    }
}


@media (min-width: 1366px) {

    .Treatment_Wrapper{
        grid-template-rows:  75px  auto;
        .Cards_Wrapper{
        grid-template-columns: 1fr 1fr 1fr;
        
        }
    }
}

@media (min-width: 2500px) {

    .Treatment_Wrapper{
        .Cards_Wrapper{
        grid-template-columns:1fr 1fr 1fr 1fr;
       
        }
    }
}