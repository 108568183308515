.voucherview{
  min-height:100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: 
  "voucherdetails"
  "voucherItems";
  .voucherItems{
    grid-area: voucherItems;
    
  }
  .voucherdetails{
    display: grid;

    grid-area: voucherdetails;


    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 
    "details status"
    "voucherdescription voucherdescription";
    .voucherdescription{
      grid-area: voucherdescription;
      text-align: center;
    }
    .details{
      grid-area: details;
      display: grid;
      grid-template-columns: 1fr 1fr ;
      grid-template-rows: 1;
    }
    .status{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      grid-area: status;
    }
  }
}