.Paymentvoucherprint{
  margin: auto;

.header{
    position: relative;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            max-width: 100%;
            height: auto;
    
            img{
                object-fit: scale-down;
                width: 100%;
                height: 100%;
            }
}
.voucherdetails{
    display: grid;

    grid-area: voucherdetails;


    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 
    "details status"
    "voucherdescription voucherdescription";
    .voucherdescription{
      grid-area: voucherdescription;
      text-align: center;
    }
    .details{
    margin: auto;

      grid-area: details;
      display: grid;
      grid-template-columns: 1fr 1fr ;
      grid-template-rows: 1;
    }
    .status{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      grid-area: status;
    }
  }
.footer{
    position: absolute;
            left: 0%;
            bottom: 0%;
            width: 100%;
            max-width: 100%;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
    
            img{
                object-fit: scale-down;
                width: 100%;
                height: 100%;
            }
}


.voucherdetails{
}


}