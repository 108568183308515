
.doc_cards{
    display: grid;
    grid-template-rows:auto ;
    width: 100%;
    margin: 0 auto;
  //  gap: 2.5%;
  gap: 15px;
    .card_doc{

        &:hover{
            background-color:  #359c8d;
            transition: 0.5s;
            .docimg{
                img{
                border: 3px solid #FFF;

                    box-shadow: 0px 0px 35px -9px rgba(0,0,0,0.33);


                }
            }
            .Doc_Extras{
                color: white;

            }
            .doccontent{
                color: white;
                a{
              //   color: #000;
                }
                .outline{
                    -webkit-text-stroke: 1px rgb(255, 255, 255);
                    color: #359c8d;
                }
            }
            h2{
                color: white;
            }
            h1{
                color: white;
            }
           
           
        }
    transition: 0.5s;

        display: grid;
        box-shadow: 0px 0px 35px -9px rgba(0,0,0,0.33);
        border-radius: 30px;
         width: 100%;
        
        text-align: center;
        background-color: rgb(241, 241, 241);
        .docimg{
            place-self: center;
            img{
                border: 3px solid  rgb(241, 241, 241);

                 //   box-shadow: 0px 0px 35px -9px rgba(0,0,0,0.33);

                border-radius: 360px;
                margin:  50px auto;
                display: block;
                width: 100px;
            }
        }.Doc_Extras{
            display: none;
            width: 80%;
            margin: auto;
            text-align: center;
           p{
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp:4; /* number of lines to show */
            -webkit-box-orient: vertical;
            visibility: visible;
           }
        }
       .doccontent{
        padding-bottom: 15px;
        width: 80%;
        margin: auto;
        text-align: center;
        h2{
            margin: 0;
               font-family: darkmode-on;
               font-weight: 900;
               font-size: 15px;
           }
           .outline{
               -webkit-text-stroke: 1px rgb(0, 0, 0);
               color: transparent;
           }
           h1{
            margin: 0;
            font-size: 12px;
            font-family: darkmode-on;
            font-weight: 900;
           
           
           }
           a{
            margin: 0;
            font-family: darkmode-on;
            font-size: 12px;
            font-weight: 900;
            color: rgb(0, 70, 128);
            word-wrap: break-word;
        }
       }
        
    }
    margin-top: 2.5%;
    margin-bottom: 2.5%;
}
@media (min-width: 600px) {
.doc_cards{
    
    .card_doc{
      
        .docimg{
            img{
                width: 200px;
            }
        }
        .doccontent{
           text-align: left;
            h2{
                   font-size: 30px;
               }
               .outline{
                   -webkit-text-stroke: 2px rgb(0, 0, 0);
               }
               h1{
                font-size: 15px;
               }
               a{
                font-size: 15px;
               
            }
           }
    }
}
}
@media (min-width: 1366px) {
    .doc_cards{

        grid-template-columns: auto auto ;
        grid-template-rows: auto ;
        //gap: 2.5%;
        place-items: center;
    }

}
@media (min-width: 2500px) {
    .doc_cards{
    .card_doc{
        grid-template-columns: 1fr 1fr 1fr ;
        .Doc_Extras{
           text-align: left;

            margin: 0;
            font-family: darkmode-on;
            font-weight: 900;
            font-size: 15px;
            display: block;
            width: 80%;
            margin: auto;
        }
    }
}
}
