.voucherviewwrapper{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas: 
    "voucher"
    "voucher_submit"
    "Paymentvoucherprint";
    .voucher{
        display: grid;
    
        grid-area: voucher;
    &_submit{
        display: grid;
    
        grid-area: voucher_submit;
    }
    }
  
.Paymentvoucherprint{
    grid-area: Paymentvoucherprint;
}

}