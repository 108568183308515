
html{
   margin: 0;
}

    .content_home{
        width:90%;
        margin: auto;
        display: grid;
        grid-template-rows:   auto;
        grid-template-areas: 
        "livecount"
        "contact"
        "directors"
        "aboutus"
        "Branches_Wrapper"
        "beforeafter"
        "TreatmentQuicklinks"
        "HomeFAQ";
        gap:25px;
        .Branches_Wrapper{
            grid-area: Branches_Wrapper;
        }
        .TreatmentQuicklinks{
            grid-area: TreatmentQuicklinks;
        }
        .HomeFAQ{
            grid-area: HomeFAQ;
            height: 100%;
            width: 100%;
         //   background-color: aqua;
        }
        .contact{
            grid-area: contact;
        }
        .livecount{
            grid-area: livecount;
        }
        .beforeafter{
            margin: 0 auto;
            display: grid;
            grid-area: beforeafter;

            margin: 15px ;
            place-items: center;
            grid-template-rows: auto auto;
        }
        .aboutus{
            grid-area: aboutus;
            width: 100%; 
        }
        .directors{
            grid-area: directors;
            width: 100%;
           // background-color: aquamarine;
        }
    }


@media (min-width: 1366px) {

}
@media (min-width:768px) {
    .content_home{
        
        .beforeafter{
        margin: 0 auto;
        grid-area: beforeafter;
        display: grid;
        margin: 15px ;
        place-items: center;
        grid-template-columns: auto auto;
    }
        .TreatmentQuicklinks{
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-areas: 
            "title  title title "
          ;
        }
        grid-template-columns: auto auto ;
        grid-template-rows:   auto auto auto auto auto auto;

        grid-template-areas: 
        "contact livecount"
        "directors directors"
        "aboutus aboutus"
        "Branches_Wrapper Branches_Wrapper"
        "beforeafter beforeafter"
        "TreatmentQuicklinks TreatmentQuicklinks"
        "HomeFAQ HomeFAQ"
        ;
    }
   
}



