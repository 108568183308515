select,
input{
    &[type=checkbox]{
        position: relative;
        width: 50px;
        height: 24px;
        appearance: none;
        background: rgb(0, 0, 0);
        border-radius: 20px;
        transition: 0.5s;
        outline: none;
        cursor: pointer;
    border: none;

&:hover{
    border: none;
}
        &:checked{
            background: #359c8d;
            transition: 0.5s;
            &::before{
                left: 25px;
            background: #ffffff;
                
            }
        }

        &::before{
            content: '';
            position: absolute;
            width: 25px;
            height: 25px;
            border-radius: 20px;
            top: 0;
            left: 0;
            background: white;
            transition: 0.5s;
            transform: scale(0.8)
        }
        margin: 0;
    }
    -webkit-appearance: none;
    font-weight: 750;
    color: black;
    //box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 6px 0 rgba(0, 0, 0, 0.19);
    //box-shadow: 4px 6px 6px 0px rgba(0,0,0,0.16);
   // border-radius: 15px;
 //   border: 2px solid #359c8d;
    font-family: 'darkmode-on';
  border: none;
  border-bottom: 3px solid #3db19f;

    background: transparent;
    font-size: 20px;
    float: left;
    margin: 1rem 0;
    padding: .25rem .5rem;
    &::placeholder{
       text-transform: capitalize;
        opacity: .25;
        transition: 0.5s;
        color: black;
    }
    &:focus::placeholder{
        opacity: 0;
    }
    &:focus{
        outline: none;
        border-bottom: 1px solid rgb(0, 0, 0);
    }
    &:hover{
        border-bottom: 3px solid #3db19f;

      //  border-bottom: 1px solid rgb(0, 0, 0);
       
        &::placeholder{
            opacity: 1;
            transition: 0.5s;
            color: #359c8d;
        }
        transition: 0.5s;
    }
    &[type=submit]{
        cursor: pointer;
    }
}
select{
    float: none;
}
.A_Tag_as_Button{
         
    border: none;
    border-radius: 30px;
    padding: .25rem 1rem;
    text-align: center;
    color: black;
    font-size: 16px;
   margin: 1rem 0;
    transition: 0.5s;
    &:hover{
        cursor: pointer;
    color: #359c8d;
    background-color: #ffffff;
    transition: 0.5s;
     //   border-bottom: 1px solid rgb(0, 0, 0);
    }
    &:focus{
        outline: none;
    }
    &.disabled,
    &:disabled{
        background-color: gray;
        color: white;
    }
    &:disabled:hover{
        border-bottom: none;
    }
    &.disabled:hover{
        border-bottom: none;
    }

    font-family: darkmode-on,sans-serif;
    font-weight: 800;
    font-style: normal;
    color: white;
    background-color: #359c8d;
    box-shadow: 10px 10px 29px -9px rgba(0,0,0,0.33);
    border: none;
    border-radius:  50px;
  //  height: 35px;
    font-size:20px;
}
button{
       
    border: none;
    border-radius: 30px;
    padding: .25rem 1rem;
    text-align: center;
    color: black;
    font-size: 16px;
    box-shadow: 4px 6px 6px 0px rgba(0,0,0,0.16);
    margin: 1rem 0;
    background-color: #359c8d;
    font-family: darkmode-on,sans-serif;
    font-weight: 800;
    transition: 0.5s;
    &:hover{
        cursor: pointer;
    color: #359c8d;
    background-color: #ffffff;
    transition: 0.5s;
     //   border-bottom: 1px solid rgb(0, 0, 0);
    }
    &:focus{
        outline: none;
    }
    &.disabled,
    &:disabled{
        background-color: gray;
        color: white;
    }
    &:disabled:hover{
        border-bottom: none;
    }
    &.disabled:hover{
        border-bottom: none;
    }

    font-family: darkmode-on,sans-serif;
    font-weight: 800;
    font-style: normal;
    color: white;
    background-color: #359c8d;
    box-shadow: 10px 10px 29px -9px rgba(0,0,0,0.33);
    border: none;
    border-radius:  50px;
    height: 35px;
    font-size:20px;
}