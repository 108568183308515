

.Cards_Wrapper{

}

/* .flip-card-container */
.flip-card-container {
  width: 100%;
  margin: auto;
  max-width: 310px;
  height: 350px;

  -webkit-perspective:1000px;
  perspective: 1000px;
}

/* .flip-card */
.flip-card {
  width: inherit;
  height: inherit;

  position: relative;
  transform-style: preserve-3d;
  transition: .6s .1s;
}

/* hover and focus-within states */
.flip-card-container:hover .flip-card,
.flip-card-container:focus-within .flip-card {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

/* .card-... */
.card-front,
.card-back {
    box-shadow: 10px 10px 29px -9px rgba(0,0,0,0.33);
    width: 100%;
    height:100%;
  border-radius: 24px;
  background:  #66be9d ;

  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;

  backface-visibility: hidden;

  display: grid;
  justify-content: center;
  align-items: center;
}

/* .card-front */
.card-front {
  transform: rotateY(0deg);
  z-index: 2;

  p{
      width: 80%;
      margin: auto;
      margin-top: 40%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 7; /* number of lines to show */
      -webkit-box-orient: vertical;
      visibility: visible;

  }


  /* figcaption */
figcaption {

    width: auto;
    margin-top: 15%;
    padding: 8px 22px;
  
    font-weight: bold;
    line-height: 1.6;
    letter-spacing: 2px;
    word-spacing: 6px;
    text-align: right;
  
    position: absolute;
    top: 0;
    right:  0;
  
   color: #000000;
    background: rgb(255, 255, 255);
    box-shadow: 10px 10px 29px -9px rgba(0,0,0,0.33);
  
    border-radius: 15px 0 0 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
}

/* .card-back */
.card-back {
  transform: rotateY(180deg);
  z-index: 1;
  margin: 0;
  display: grid;
  button{
     padding: 10px;
  }
}

/* figure */
figure {
  z-index: -1;
}

/* figure, .img-bg */
figure,
.img-bg {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  /* img */
img {
  height: 100%;
  border-radius: 24px;
}



}





/* hover state */
.flip-card-container:hover .card-front .img-bg::before {
  width: 6px;

}





@media (min-width: 600px) {
  .cards{
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: auto;
      gap: 2.5%;
      place-items: center;
  }

}
@media (min-width: 1366px) {
  .cards{
      grid-template-columns: 1fr 1fr  1fr 1fr;
      grid-template-rows: auto;
      gap: 2.5%;
      place-items: center;
  }

}
@media (min-width: 2500px) {
  
  .card{
      grid-template-columns: 1fr 1fr 1fr ;
    
  }

}

