.livecount{
    transition: 0.5s;

    margin: 0;
    padding: 0;
    grid-area: livecount;
    display: grid;
    grid-template-columns: auto auto ;
    grid-template-rows: auto;
    font-weight: 900;
    text-align: center;
    place-items: center;
    width: 95%;
    margin: auto;
    gap: 15px;

   .count_Item{
       display: grid;
     //  width: 100%;
     //  height: 100%;
     place-items: center;
        width: 100px;
    height: 100px;
p{
    font-size: 8px;
}
    grid-template-rows:1fr 1fr ;
    .img{
     place-items: center;
     margin: auto;

        img{
            width: 50px;
            border-radius: 50%;
            background-color: #FFF;
        }
    }
    a{   
        background-color: bisque;
        color: #FFF; 
        &:hover{
        color: #359c8d;

        }
      
    }
    &:hover{
    transition: 0.5s;
    transform: scale(1.1); // scaling the item on hover
        background-color: #359c8d;

         color: #FFF;
         a{
            color: #FFF;
         }
        }
        font-size: 15px;
        font-family: darkmode-on,sans-serif;
        font-weight: 900;
        font-style: normal;
        box-shadow: 0px 0px 35px -9px rgba(0,0,0,0.33);

           background-color: #ffffff;
           border-radius: 20px;
           color: #359c8d;

           
    }
   margin-top: 10%;
   margin-bottom: 10%;
}

@media (min-width:468px) {

    .livecount{
        .count_Item{
            p{
    font-size:10px;

            }
            .img{
           
                   img{
                       width: 50px;
                   }
                }
            width:100px;
            height: 100px;
          //  background-color: rgb(255, 255, 46);
        }
        grid-template-columns: auto auto;
        grid-template-rows: auto auto;
    }
}
@media (min-width:768px) {

    .livecount{
      
        .count_Item{
            p{
                font-size: 14px;
            
                        }
            .img{

                img{
                    width: 75px;
                }
                }
           //     background-color: aquamarine;
            width: 150px;
            height: 150px;
        }
        grid-template-columns: auto auto;
        grid-template-rows: auto auto;
    }
}