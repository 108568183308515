.HomeFAQ{
    //background-color: aqua;
    //height: 100%;
  
    margin-top: 5%;
    margin-bottom: 5%;
}

.wrapper {
    width: 80%;

    margin: auto;

    .faqcontainer {
      background-color: #FFF;
      color: black;
      border-radius: 20px;
      box-shadow: 0px 0px 35px -9px rgba(0,0,0,0.33);
  
      margin: 20px 0;
      transition: 0.5s;
  
      &:hover{
        color: #FFF;
        background-color: #359c8d;
        .question{
          &:after{
          color: #FFF;
    
          }
        }
      }
      
    }
  
  h1 {
    text-align: center;

    margin-bottom: 20px;
  }
  
 
  
  .question {
    font-size: 1.2rem;
    font-weight: 600;
    padding: 20px 80px 20px 20px;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    
  }
  
  .question::after {
    content: "\002B";
    font-size: 2.2rem;
    position: absolute;
    right: 20px;
    transition: 0.2s;
    color: #359c8d;
  }
  
  .question.active::after {
    transform: rotate(45deg);
  }
  
  .answercont {
    max-height: 0;
    overflow: hidden;
    transition: 0.3s;
  }
  
  .answer {
    padding: 0 20px 20px;
    line-height: 1.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp:3; /* number of lines to show */
    -webkit-box-orient: vertical;
    visibility: visible;
  }
  
  .question.active + .answercont {
  }
}


  @media screen and (max-width: 790px){
    html {
      font-size: 14px;
    }
    .wrapper {
    width: 80%;
  }
  
  }